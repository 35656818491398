import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {ApolloError} from '@apollo/client/core';
import { PlusApollo, dayjs } from '@karve.it/core';
import { ComponentStore } from "@ngrx/component-store";
import { tapResponse } from "@ngrx/operators";
import { gql } from "graphql-tag";
import { cloneDeep, mergeWith, uniq } from "lodash";
import { filter, map, skip, switchMap, tap, withLatestFrom } from "rxjs";

import { environment } from "../../environments/environment";
import { baseEnvironment } from "../../environments/environment.base";
import { PreviewReportFilter, PreviewReportGQL, PreviewReportQueryVariables } from "../../generated/graphql.generated";

import { ExtractObservableType } from "../../typescript";
import { GraphQLModule } from "../core/public-api";
import { transactionVolumeChartOptions } from "../dashboard/dashboard.constants";
import { PeriodService } from "../dashboard/period.service";
import { ReportParamsForm } from "../dynamic-reports/preview-dynamic-report/preview-dynamic-report.component";
import { Country, DistanceUnit, jurisdictions } from "../global.constants";
import { formatCurrency } from "../lib.ts/currency.util";
import { AdvancedPeriodOptions } from "../reports/reports.constants";
import { BrandingService } from "../services/branding.service";
import { QueryParamsService } from "../shared/query-params.service";
import { currentTimeSeconds, dateToUnixSeconds, getBeginningOfTimeUnix, getEndOfTimeUnix } from "../time";
import { getApolloErrorMessage } from "../utilities/errors.util";

import { CurrencyDelta, AmountDelta } from "./delta/delta.component";

export const comparedToLsKey = 'compared-to' as const;

export const comparedToValues = [
  'Previous Month',
  'Same Month Last Year',
] as const;

export type ComparedTo = typeof comparedToValues[number];

export type LayoutSize = 'small' | 'medium' | 'large';

export const defaultLayoutSize: LayoutSize = 'large';

interface ReportData {
  headers: string[];
  rows: string[][];
}

const pipelineColorMap = {
  'Lead': '--error',
  'Estimate': '--orange-500',
  'Booking': '--info',
  'Invoice': '--green-500',
};

export interface DashboardState {
  period: AdvancedPeriodOptions | 'Month of Year';
  customPeriod: Date[];
  comparedTo: ComparedTo;
  month: string;
  currency: string;
  pipeline: string[][];
  pipelineLoading: boolean;
  pipelineError: string | null;
  averageInvoiceSize: CurrencyAmount;
  averageInvoiceSizeLoading: boolean;
  averageInvoiceSizeError: string | null;
  pastAverageInvoiceSize: CurrencyAmount;
  pastAverageInvoiceSizeLoading: boolean;
  leadsTotal: number;
  leadsConverted: number;
  pastLeadsTotal: number;
  pastLeadsConverted: number;
  obeTotal: number;
  obeConverted: number;
  pastObeTotal: number;
  pastObeConverted: number;
  cscTotal: number;
  cscConverted: number;
  pastCscTotal: number;
  pastCscConverted: number;
  leadConversionsLoading: boolean;
  leadConversionsError: string | null;
  pastLeadConversionsLoading: boolean;
  oseTotal: number;
  oseConverted: number;
  pastOseTotal: number;
  pastOseConverted: number;
  oseConversionLoading: boolean;
  oseConversionError: string | null;
  pastOseConversionLoading: boolean;
  leadTotal: number;
  leadConverted: number;
  realizedRevenue: CurrencyAmount;
  scheduledRevenue: CurrencyAmount;
  expectedRevenue: CurrencyAmount;
  pastRealizedRevenue: CurrencyAmount;
  pastScheduledRevenue: CurrencyAmount;
  pastExpectedRevenue: CurrencyAmount;
  distanceBooked: number;
  distanceCompleted: number;
  travelTimeBooked: number;
  travelTimeCompleted: number;
  pastDistanceBooked: number;
  pastDistanceCompleted: number;
  pastTravelTimeBooked: number;
  pastTravelTimeCompleted: number;
  revenueAndLogisticsLoading: boolean;
  revenueAndLogisticsError: string | null;
  pastRevenueAndLogisticsLoading: boolean;
  transactionsPaidPerDay: string[][];
  eventsCreatedPerDay: string[][];
  eventsHappeningPerDay: string[][];
  activityLoading: boolean;
  activityError: string | null;
  eventsCreated: ReportData;
  eventsCreatedLoading: boolean;
  eventsCreatedError: string | null;
  eventsHappening: ReportData;
  eventsHappeningLoading: boolean;
  eventsHappeningError: string | null;
  sources: string[][];
  sourcesLoading: boolean;
  sourcesError: string | null;
  units: DistanceUnit;
  lastReload: number;
  layoutSize: LayoutSize;
}

export interface CurrencyAmount {
  currency: string;
  amount: number;
}

type DashboardReport =
| 'pipeline'
| 'averageInvoiceSize'
|  'sources'
|  'eventsCreated'
|  'eventsHappening'
|  'revenueAndLogistics'
|  'leadConversions'
|  'oseConversion'
|  'transactionsPaid'
|  'eventsCreatedPerDay'
|  'eventsHappeningPerDay';

const placeholderEventHeaders = [
  'Event Type',
  'Net Subtotal',
  'Event Count',
  'Average Net Subtotal per Event',
];

const placeholderSources = [
  [ 'Not Specified', '0', '0', 'USD' ],
];

const initialState: DashboardState = {
  period: 'Month of Year',
  customPeriod: getCurrentMonthPeriod(),
  comparedTo: 'Same Month Last Year',
  month: getCurrentMonth(),
  currency: baseEnvironment.defaultCurrency,
  pipeline: [],
  pipelineLoading: false,
  pipelineError: null,
  averageInvoiceSize: getDefaultAmount(),
  averageInvoiceSizeLoading: false,
  averageInvoiceSizeError: null,
  pastAverageInvoiceSize: getDefaultAmount(),
  pastAverageInvoiceSizeLoading: false,
  leadsTotal: 0,
  leadsConverted: 0,
  pastLeadsTotal: 0,
  pastLeadsConverted: 0,
  obeTotal: 0,
  obeConverted: 0,
  pastObeTotal: 0,
  pastObeConverted: 0,
  cscTotal: 0,
  cscConverted: 0,
  pastCscTotal: 0,
  pastCscConverted: 0,
  leadConversionsLoading: false,
  leadConversionsError: null,
  pastLeadConversionsLoading: false,
  oseTotal: 0,
  oseConverted: 0,
  pastOseTotal: 0,
  pastOseConverted: 0,
  oseConversionLoading: false,
  oseConversionError: null,
  pastOseConversionLoading: false,
  leadTotal: 0,
  leadConverted: 0,
  realizedRevenue: getDefaultAmount(),
  scheduledRevenue: getDefaultAmount(),
  expectedRevenue: getDefaultAmount(),
  pastRealizedRevenue: getDefaultAmount(),
  pastScheduledRevenue: getDefaultAmount(),
  pastExpectedRevenue: getDefaultAmount(),
  revenueAndLogisticsLoading: false,
  revenueAndLogisticsError: null,
  pastRevenueAndLogisticsLoading: false,
  transactionsPaidPerDay: [],
  eventsCreatedPerDay: [],
  eventsHappeningPerDay: [],
  activityLoading: false,
  activityError: null,
  distanceBooked: 0,
  distanceCompleted: 0,
  travelTimeBooked: 0,
  travelTimeCompleted: 0,
  pastDistanceBooked: 0,
  pastDistanceCompleted: 0,
  pastTravelTimeBooked: 0,
  pastTravelTimeCompleted: 0,
  eventsCreated: {
    headers: placeholderEventHeaders,
    rows: [],
  },
  eventsCreatedLoading: false,
  eventsCreatedError: null,
  eventsHappening: {
    headers: placeholderEventHeaders,
    rows: [],
  },
  eventsHappeningLoading: false,
  eventsHappeningError: null,
  sources: placeholderSources,
  sourcesLoading: false,
  sourcesError: null,
  units: environment.defaultUnits,
  lastReload: currentTimeSeconds(),
  layoutSize: defaultLayoutSize,
};

function getDefaultAmount() {
  return {
    currency: baseEnvironment.defaultCurrency,
    amount: 0,
  };
}

function getCurrentMonth() {
  return dayjs().format('MMMM YYYY');
}

function getCurrentMonthPeriod() {
  const today = dayjs();
  return [ today.startOf('month').toDate(), today.endOf('month').toDate() ];
}

@Injectable()
export class DashboardStore extends ComponentStore<DashboardState> {

  constructor(
    private previewReportGQL: PreviewReportGQL,
    private graphqlModule: GraphQLModule,
    private queryParamsService: QueryParamsService,
    private branding: BrandingService,
    private router: Router,
    private apollo: PlusApollo,
    private periodService: PeriodService,
  ) {
    super(initialState);
  }

  readonly period$ = this.select((state) => state.period);
  readonly setPeriod = this.updater((state, period: AdvancedPeriodOptions | 'Month of Year'): DashboardState => ({ ...state, period }));

  readonly customPeriod$ = this.select((state) => state.customPeriod);
  readonly setCustomPeriod = this.updater((state, customPeriod: Date[]): DashboardState => ({ ...state, customPeriod }));

  readonly month$ = this.select((state) => state.month);
  readonly setMonth = this.updater((state, month: string): DashboardState => ({ ...state, month }));

  readonly comparedTo$ = this.select((state) => state.comparedTo);
  readonly setComparedTo = this.updater((state, comparedTo: ComparedTo): DashboardState => ({ ...state, comparedTo }));

  readonly currency$ = this.select((state) => state.currency);
  readonly setCurrency = this.updater((state, currency: string): DashboardState => ({ ...state, currency }));

  readonly lastReload$ = this.select((state) => state.lastReload);

  readonly isMonthMode$ = this.select((state) => state.period === 'Month of Year');

  readonly deltasEnabled$ = this.select((state) => state.period === 'Month of Year' || state.period === 'MTD');

  readonly customPeriodDisabled$ = this.select((state) => state.period !== 'Custom');

  readonly somethingLoading$ = this.select((state) => {

    const loadingProps: (keyof DashboardState)[] = [
      'pipelineLoading',
      'averageInvoiceSizeLoading',
      'pastAverageInvoiceSizeLoading',
      'leadConversionsLoading',
      'pastLeadConversionsLoading',
      'oseConversionLoading',
      'pastOseConversionLoading',
      'revenueAndLogisticsLoading',
      'pastRevenueAndLogisticsLoading',
      'activityLoading',
      'eventsCreatedLoading',
      'eventsHappeningLoading',
      'sourcesLoading',
    ];

    return loadingProps.some((prop) => state[prop]);
  });

  readonly pastMonth$ = this.select(
    this.month$,
    this.comparedTo$,
    (month, comparedTo) => {

      const currentMonthDayJs = dayjs(month, 'MMMM YYYY');

      const substractUnit = comparedTo === 'Previous Month' ? 'month' : 'year';

      return currentMonthDayJs.subtract(1, substractUnit).format('MMMM YYYY');
    }
  );

  readonly unixPeriod$ = this.select(
    this.customPeriod$,
    (customPeriod) => {
      const [ startDate, endDate ] = customPeriod;
      return {
        start: startDate ? dayjs(startDate).startOf('day').unix() : getBeginningOfTimeUnix(),
        end: endDate ? dayjs(endDate).endOf('day').unix() : getEndOfTimeUnix(),
      };
    },
  );

  readonly pastUnixPeriod$ = this.select(
    this.period$,
    this.pastMonth$,
    this.comparedTo$,
    (period, pastMonth, comparedTo) => {

      if (period === 'Month of Year') {

        const pastMonthDayJs = dayjs(pastMonth, 'MMMM YYYY');

        return {
          start: pastMonthDayJs.startOf('month').unix(),
          end: pastMonthDayJs.endOf('month').unix(),
        };

      } else if (period === 'MTD') {

        const today = dayjs();

        const substractUnit = comparedTo === 'Previous Month' ? 'month' : 'year';

        const previousMonth = today.subtract(1, substractUnit);

        const daysInPreviousMonth = previousMonth.daysInMonth();

        const dayOfTheMonth = Math.min(today.date(), daysInPreviousMonth);

        return {
          start: previousMonth.startOf('month').unix(),
          end: previousMonth.date(dayOfTheMonth).endOf('day').unix(),
        }
      }
    },
  );

  readonly displayPeriod$ = this.select(
    this.period$,
    this.month$,
    this.customPeriod$,
    (period, month, customPeriod) => {

      if (period === 'Month of Year') {
        return month;
      }

      if (period !== 'Custom') {
        return period;
      }

      return customPeriod.map((date) => dayjs(date).format('MMM D, YYYY')).join(' - ');
    },
  );

  readonly realizedBy$ = this.select(
    this.period$,
    this.customPeriod$,
    this.month$,
    (period, customPeriod, month) => {

      if (period === 'Month of Year') {
        return dayjs(month, 'MMMM YYYY').endOf('month').unix();
      }

      const [ _startDate, endDate ] = customPeriod;
      return endDate ? dateToUnixSeconds(endDate) : getEndOfTimeUnix();
    }
  );

  readonly pastRealizedBy$ = this.select(
    this.period$,
    this.month$,
    this.comparedTo$,
    (period, month, comparedTo) => {

      if (period !== 'MTD' && period !== 'Month of Year') { return; }

      const today = dayjs();

      const selectedMonthDayJs = dayjs(month, 'MMMM YYYY');

      const baseDate = period === 'Month of Year' ? selectedMonthDayJs : today;

      const substractUnit = comparedTo === 'Previous Month' ? 'month' : 'year';

      const previousMonth = baseDate.subtract(1, substractUnit);

      const isSelectedMonthCurrent = selectedMonthDayJs.isSame(today, 'month');

      if (period === 'Month of Year' && !isSelectedMonthCurrent) {
        return previousMonth.endOf('month').unix();
      }

      const daysInPastMonth = previousMonth.daysInMonth();

      const currentDayOfTheMonth = Math.min(today.date(), daysInPastMonth);

      return previousMonth.date(currentDayOfTheMonth).endOf('day').unix();
    }
  ).pipe(filter((realizedBy) => realizedBy !== undefined));

  readonly pipeline$ = this.select((state) => state.pipeline);
  readonly pipelineLoading$ = this.select((state) => state.pipelineLoading);
  readonly pipelineChartData$ = this.select(this.pipeline$, (pipeline) => {

    const labels: string[] = [];

    const data: number[] = [];

    const backgroundColor: string[] = [];

    const documentStyle = getComputedStyle(document.documentElement);

    for (const [ label, count ] of pipeline) {
      labels.push(label);
      data.push(parseInt(count));
      backgroundColor.push(documentStyle.getPropertyValue(pipelineColorMap[label]));
    }

    return {
      labels,
      datasets: [
        {
          data,
          backgroundColor,
        },
      ],
    };
  });

  readonly averageInvoiceSize$ = this.select((state) => state.averageInvoiceSize);
  readonly averageInvoiceSizeLoading$ = this.select((state) => state.averageInvoiceSizeLoading);
  readonly pastAverageInvoiceSize$ = this.select((state) => state.pastAverageInvoiceSize);
  readonly pastAverageInvoiceSizeLoading$ = this.select((state) => state.pastAverageInvoiceSizeLoading);

  readonly sources$ = this.select((state) => state.sources);
  readonly sourcesLoading$ = this.select((state) => state.sourcesLoading);
  readonly sourcedData$ = this.select(this.sources$, (rows) => {

    const totalRevenue = rows.reduce((acc, row) => acc + parseFloat(row[2]), 0);

    const data: {
      source: string;
      jobCount: number;
      tooltip: string;
      amount: number;
      currency: string;
      percent: number;
    }[] = [];

    for (const row of rows) {

      const jobCount = parseInt(row[1]);

      const amount = parseFloat(row[2]);

      const percent = totalRevenue ? (amount / totalRevenue) * 100 : 0;

      data.push({
        source: row[0],
        jobCount,
        tooltip: `${jobCount} leads`,
        amount,
        currency: row[3],
        percent,
      });
    }

    data.sort((a, b) => b.jobCount - a.jobCount);

    return data;
  });

  readonly comparedToText$ = this.select(
    this.period$,
    this.month$,
    this.pastMonth$,
    this.comparedTo$,
    (period, selectedMonth, pastMonth, comparedTo) => {

      // If user is in month mode and they selected a month other than the current one, show the name of the past month explicitly
      if (period === 'Month of Year') {

        const selectedMonthDate = dayjs(selectedMonth, 'MMMM YYYY');

        const isCurrentMonth = selectedMonthDate.isSame(dayjs(), 'month');

        if (!isCurrentMonth) {
          return `since ${pastMonth}`;
        }
      }

      return comparedTo === 'Previous Month' ? 'since last month' : 'since this month last year';
    },
  );

  readonly obeTotal$ = this.select((state) => state.obeTotal);
  readonly obeConverted$ = this.select((state) => state.obeConverted);
  readonly obePercent$ = this.select(
    this.obeTotal$,
    this.obeConverted$,
    (total, converted) => this.getPercent({ total, converted }),
  );

  readonly pastObeTotal$ = this.select((state) => state.pastObeTotal);
  readonly pastObeConverted$ = this.select((state) => state.pastObeConverted);
  readonly pastObePercent$ = this.select(
    this.pastObeTotal$,
    this.pastObeConverted$,
    (total, converted) => this.getPercent({ total, converted }),
  );

  readonly cscTotal$ = this.select((state) => state.cscTotal);
  readonly cscConverted$ = this.select((state) => state.cscConverted);
  readonly cscPercent$ = this.select(
    this.cscTotal$,
    this.cscConverted$,
    (total, converted) => this.getPercent({ total, converted }),
  );

  readonly pastCscTotal$ = this.select((state) => state.pastCscTotal);
  readonly pastCscConverted$ = this.select((state) => state.pastCscConverted);
  readonly pastCscPercent$ = this.select(
    this.pastCscTotal$,
    this.pastCscConverted$,
    (total, converted) => this.getPercent({ total, converted }),
  );

  readonly oseTotal$ = this.select((state) => state.oseTotal);
  readonly oseConverted$ = this.select((state) => state.oseConverted);
  readonly osePercent$ = this.select(
    this.oseTotal$,
    this.oseConverted$,
    (total, converted) => this.getPercent({ total, converted }),
  );

  readonly pastOseTotal$ = this.select((state) => state.pastOseTotal);
  readonly pastOseConverted$ = this.select((state) => state.pastOseConverted);
  readonly pastOsePercent$ = this.select(
    this.pastOseTotal$,
    this.pastOseConverted$,
    (total, converted) => this.getPercent({ total, converted }),
  );

  readonly leadsTotal$ = this.select((state) => state.leadsTotal);
  readonly leadsConverted$ = this.select((state) => state.leadsConverted);
  readonly leadsPercent$ = this.select(
    this.leadsTotal$,
    this.leadsConverted$,
    (total, converted) => this.getPercent({ total, converted }),
  );

  readonly pastLeadsTotal$ = this.select((state) => state.pastLeadsTotal);
  readonly pastLeadsConverted$ = this.select((state) => state.pastLeadsConverted);
  readonly pastLeadsPercent$ = this.select(
    this.pastLeadsTotal$,
    this.pastLeadsConverted$,
    (total, converted) => this.getPercent({ total, converted }),
  );

  readonly leadsLoading$ = this.select((state) => state.leadConversionsLoading);
  readonly pastLeadsLoading$ = this.select((state) => state.pastLeadConversionsLoading);
  readonly oseLoading$ = this.select((state) => state.oseConversionLoading);
  readonly pastOseLoading$ = this.select((state) => state.pastOseConversionLoading);

  readonly realizedRevenue$ = this.select((state) => state.realizedRevenue);
  readonly scheduledRevenue$ = this.select((state) => state.scheduledRevenue);
  readonly expectedRevenue$ = this.select((state) => state.expectedRevenue);
  readonly pastRealizedRevenue$ = this.select((state) => state.pastRealizedRevenue);
  readonly pastScheduledRevenue$ = this.select((state) => state.pastScheduledRevenue);
  readonly pastExpectedRevenue$ = this.select((state) => state.pastExpectedRevenue);

  readonly revenueAndLogisticsLoading$ = this.select((state) => state.revenueAndLogisticsLoading);
  readonly pastRevenueAndLogisticsLoading$ = this.select((state) => state.pastRevenueAndLogisticsLoading);

  readonly distanceBooked$ = this.select((state) => state.distanceBooked);
  readonly distanceCompleted$ = this.select((state) => state.distanceCompleted);
  readonly travelTimeBooked$ = this.select((state) => state.travelTimeBooked);
  readonly travelTimeCompleted$ = this.select((state) => state.travelTimeCompleted);

  readonly pastDistanceBooked$ = this.select((state) => state.pastDistanceBooked);
  readonly pastDistanceCompleted$ = this.select((state) => state.pastDistanceCompleted);
  readonly pastTravelTimeBooked$ = this.select((state) => state.pastTravelTimeBooked);
  readonly pastTravelTimeCompleted$ = this.select((state) => state.pastTravelTimeCompleted);

  readonly eventsCreated$ = this.select((state) => state.eventsCreated);
  readonly eventsCreatedLoading$ = this.select((state) => state.eventsCreatedLoading);
  readonly eventsCreatedError$ = this.select((state) => state.eventsCreatedError);
  readonly eventsHappening$ = this.select((state) => state.eventsHappening);
  readonly eventsHappeningLoading$ = this.select((state) => state.eventsHappeningLoading);
  readonly eventsHappeningError$ = this.select((state) => state.eventsHappeningError);

  readonly layoutSize$ = this.select((state) => state.layoutSize);
  readonly setLayoutSize = this.updater((state, layoutSize: LayoutSize): DashboardState => ({ ...state, layoutSize }));

  readonly errors$ = this.select((state) => {

    const errors = [
      state.pipelineError,
      state.averageInvoiceSizeError,
      state.leadConversionsError,
      state.oseConversionError,
      state.revenueAndLogisticsError,
      state.activityError,
      state.eventsCreatedError,
      state.eventsHappeningError,
      state.sourcesError,
    ];

    return uniq(errors.filter(Boolean));
  });

  /**
   * TRIGGERS
   */

  readonly reloadDataTrigger$ = this.select(
    this.currency$,
    this.unixPeriod$,
    this.graphqlModule.zone,
    (currency, { start, end }) => {
      return { start, end, currency };
    },
    { debounce: true },
  );

  readonly reloadPastDataTrigger$ = this.select(
    this.deltasEnabled$,
    this.currency$,
    this.pastUnixPeriod$,
    this.graphqlModule.zone,
    (deltasEnabled, currency, pastUnixPeriod) => {

      if (!pastUnixPeriod) {
        return { deltasEnabled, currency, start: undefined, end: undefined };
      }

      const { start, end } = pastUnixPeriod;
      return { deltasEnabled, currency, start, end };
    },
    { debounce: true },
  ).pipe(filter(({ deltasEnabled }) => deltasEnabled));

  readonly reloadPipelineTrigger$ = this.select(
    this.currency$,
    this.graphqlModule.zone,
    (currency) => {
      return { start: getBeginningOfTimeUnix(), end: getEndOfTimeUnix(), currency };
    },
    { debounce: true },
  );

  readonly resetCustomPeriodTrigger$ = this.select(
    this.period$,
    this.month$,
    (period, month) => ({ period, month })
  );

  readonly setQueryParamsTrigger$ = this.select(
    this.period$,
    this.customPeriod$,
    this.month$,
    this.comparedTo$,
    (period, customPeriod, month, comparedTo) => ({ period, customPeriod, month, comparedTo }),
    { debounce: true },
  );

  /**
   * VIEW MODELS
   */

  readonly headerViewModel$ = this.select({
    loading: this.somethingLoading$,
    lastReload: this.lastReload$,
    comparedTo: this.comparedTo$,
    currency: this.currency$,
    period: this.period$,
    month: this.month$,
    customPeriod: this.customPeriod$,
    isMonthMode: this.isMonthMode$,
    showComparedTo: this.deltasEnabled$,
    customPeriodDisabled: this.customPeriodDisabled$,
    errors: this.errors$,
    displayPeriod: this.displayPeriod$,
  });

  readonly averageInvoiceSizeViewModel$ = this.select({
    averageInvoiceSize: this.averageInvoiceSize$,
    queryParams: this.getReportQueryParams('averageInvoiceSize'),
    loading: this.select((state) => state.averageInvoiceSizeLoading),
    error: this.select((state) => state.averageInvoiceSizeError),
    delta: this.select(
      this.averageInvoiceSizeLoading$,
      this.pastAverageInvoiceSizeLoading$,
      this.averageInvoiceSize$,
      this.pastAverageInvoiceSize$,
      (currentLoading, pastLoading, current, past) => ({
        ...this.getCurrencyDelta({ current, past }),
        loading: currentLoading || pastLoading,
      }),
    ),
  });

  readonly sourcesViewModel$ = this.select({
    sources: this.sources$,
    data: this.sourcedData$,
    queryParams: this.getReportQueryParams('sources'),
    loading: this.sourcesLoading$,
    error: this.select((state) => state.sourcesError),
    isEmpty: this.select(
      this.sources$,
      this.sourcesLoading$,
      (data, loading) => !data.length && !loading,
    ),
    period: this.displayPeriod$,
  });

  readonly pipelineViewModel$ = this.select({
    chartData: this.pipelineChartData$,
    isEmpty: this.select(
      this.pipeline$,
      this.pipelineLoading$,
      (data, loading) => !data.length && !loading,
    ),
    error: this.select((state) => state.pipelineError),
    queryParams: this.getReportQueryParams('pipeline'),
    loading: this.pipelineLoading$,
  });

  readonly eventsViewModel$ = this.select({
    eventsCreated: this.select(this.eventsCreated$, (eventsCreated) => this.formatEventData(eventsCreated)),
    createdLoading: this.eventsCreatedLoading$,
    createdEmpty: this.select(
      this.eventsCreated$,
      this.eventsCreatedLoading$,
      (created, loading) => !created.rows.length && !loading,
    ),
    eventsHappening: this.select(this.eventsHappening$, (eventsHappening) => this.formatEventData(eventsHappening)),
    happeningLoading: this.eventsHappeningLoading$,
    happeningEmpty: this.select(
      this.eventsHappening$,
      this.eventsHappeningLoading$,
      (happening, loading) => !happening.rows.length && !loading,
    ),
    createdQueryParams: this.getReportQueryParams('eventsCreated'),
    happeningQueryParams: this.getReportQueryParams('eventsHappening'),
    error: this.select(
      this.eventsCreatedError$,
      this.eventsHappeningError$,
      (created, happening) => created || happening,
    ),
    eventsCreatedError: this.eventsCreatedError$,
    eventsHappeningError: this.eventsHappeningError$,
    period: this.displayPeriod$,
  });

  readonly activityViewModel$ = this.select({
    transactionsHappeningPerDay: this.select((state) => state.transactionsPaidPerDay),
    isMonthMode: this.isMonthMode$,
    period: this.unixPeriod$,
    eventsCreatedPerDay: this.select((state) => state.eventsCreatedPerDay),
    eventsHappeningPerDay: this.select((state) => state.eventsHappeningPerDay),
    transactionsPaidQueryParams: this.getReportQueryParams('transactionsPaid'),
    eventsCreatedQueryParams: this.getReportQueryParams('eventsCreatedPerDay'),
    eventsHappeningQueryParams: this.getReportQueryParams('eventsHappeningPerDay'),
    loading: this.select((state) => state.activityLoading),
    error: this.select((state) => state.activityError),
    displayPeriod: this.displayPeriod$,
  });

  readonly revenueViewModel$ = this.select({
    realized: this.realizedRevenue$,
    realizedRevenueDelta: this.select(
      this.revenueAndLogisticsLoading$,
      this.pastRevenueAndLogisticsLoading$,
      this.realizedRevenue$,
      this.pastRealizedRevenue$,
      (currentLoading, pastLoading, current, past) => ({
        ...this.getCurrencyDelta({ current, past }),
        loading: currentLoading || pastLoading,
      }),
    ),
    scheduled: this.scheduledRevenue$,
    scheduledRevenueDelta: this.select(
      this.revenueAndLogisticsLoading$,
      this.pastRevenueAndLogisticsLoading$,
      this.scheduledRevenue$,
      this.pastScheduledRevenue$,
      (currentLoading, pastLoading, current, past) => ({
        ...this.getCurrencyDelta({ current, past }),
        loading: currentLoading || pastLoading,
      }),
    ),
    expected: this.expectedRevenue$,
    expectedRevenueDelta: this.select(
      this.revenueAndLogisticsLoading$,
      this.pastRevenueAndLogisticsLoading$,
      this.expectedRevenue$,
      this.pastExpectedRevenue$,
      (currentLoading, pastLoading, current, past) => ({
        ...this.getCurrencyDelta({ current, past }),
        loading: currentLoading || pastLoading,
      }),
    ),
    queryParams: this.getReportQueryParams('revenueAndLogistics'),
    loading: this.select((state) => state.revenueAndLogisticsLoading),
    error: this.select((state) => state.revenueAndLogisticsError),
    period: this.displayPeriod$,
  });

  readonly logisticsViewModel$ = this.select({
    distanceBooked: this.distanceBooked$,
    distanceBookedDelta: this.select(
      this.revenueAndLogisticsLoading$,
      this.pastRevenueAndLogisticsLoading$,
      this.distanceBooked$,
      this.pastDistanceBooked$,
      (currentLoading, pastLoading, current, past) => ({
        ...this.getAmountDelta({ current, past }),
        loading: currentLoading || pastLoading,
      }),
    ),
    distanceCompleted: this.distanceCompleted$,
    distanceCompletedDelta: this.select(
      this.revenueAndLogisticsLoading$,
      this.pastRevenueAndLogisticsLoading$,
      this.distanceCompleted$,
      this.pastDistanceCompleted$,
      (currentLoading, pastLoading, current, past) => ({
        ...this.getAmountDelta({ current, past }),
        loading: currentLoading || pastLoading,
      }),
    ),
    travelTimeBooked: this.travelTimeBooked$,
    travelTimeBookedDelta: this.select(
      this.revenueAndLogisticsLoading$,
      this.pastRevenueAndLogisticsLoading$,
      this.travelTimeBooked$,
      this.pastTravelTimeBooked$,
      (currentLoading, pastLoading, current, past) => ({
        ...this.getAmountDelta({ current, past }),
        loading: currentLoading || pastLoading,
      }),
    ),
    travelTimeCompleted: this.travelTimeCompleted$,
    travelTimeCompletedDelta: this.select(
      this.revenueAndLogisticsLoading$,
      this.pastRevenueAndLogisticsLoading$,
      this.travelTimeCompleted$,
      this.pastTravelTimeCompleted$,
      (currentLoading, pastLoading, current, past) => ({
        ...this.getAmountDelta({ current, past }),
        loading: currentLoading || pastLoading,
      }),
    ),
    units: this.select((state) => state.units),
    queryParams: this.getReportQueryParams('revenueAndLogistics'),
    loading: this.revenueAndLogisticsLoading$,
    error: this.select((state) => state.revenueAndLogisticsError),
    period: this.displayPeriod$,
  });

  readonly conversionsViewModel$ = this.select({
    leadsTotal: this.leadsTotal$,
    leadsConverted: this.leadsConverted$,
    leadsPercent: this.leadsPercent$,
    leadsPercentDelta: this.select(
      this.leadsLoading$,
      this.pastLeadsLoading$,
      this.leadsPercent$,
      this.pastLeadsPercent$,
      (currentLoading, pastLoading, current, past) => ({
        ...this.getAmountDelta({ current, past }),
        loading: currentLoading || pastLoading,
      }),
    ),
    obeTotal: this.obeTotal$,
    obeConverted: this.obeConverted$,
    obePercent: this.obePercent$,
    obePercentDelta: this.select(
      this.leadsLoading$,
      this.pastLeadsLoading$,
      this.obePercent$,
      this.pastObePercent$,
      (currentLoading, pastLoading, current, past) => ({
        ...this.getAmountDelta({ current, past }),
        loading: currentLoading || pastLoading,
      }),
    ),
    cscTotal: this.cscTotal$,
    cscConverted: this.cscConverted$,
    cscPercent: this.cscPercent$,
    cscPercentDelta: this.select(
      this.leadsLoading$,
      this.pastLeadsLoading$,
      this.cscPercent$,
      this.pastCscPercent$,
      (currentLoading, pastLoading, current, past) => ({
        ...this.getAmountDelta({ current, past }),
        loading: currentLoading || pastLoading,
      }),
    ),
    oseTotal: this.oseTotal$,
    oseConverted: this.oseConverted$,
    osePercent: this.osePercent$,
    osePercentDelta: this.select(
      this.oseLoading$,
      this.pastOseLoading$,
      this.osePercent$,
      this.pastOsePercent$,
      (currentLoading, pastLoading, current, past) => ({
        ...this.getAmountDelta({ current, past }),
        loading: currentLoading || pastLoading,
      }),
    ),
    leadsLoading: this.leadsLoading$,
    oseLoading: this.oseLoading$,
    error: this.select((state) => state.leadConversionsError || state.oseConversionError),
    leadsQueryParams: this.getReportQueryParams('leadConversions'),
    oseQueryParams: this.getReportQueryParams('oseConversion'),
    period: this.displayPeriod$,
  });

  deltaViewModel$ = this.select({
    comparedToText: this.comparedToText$,
    enabled: this.deltasEnabled$,
  });

  /**
   * EFFECTS
   */

  readonly reloadPipeline = this.effect(() => this.reloadPipelineTrigger$.pipe(
    map(({ currency, start, end }) => this.getQueryVars({ start, end, currency, report: 'pipeline' })),
    tap(() => this.patchState({
      pipelineLoading: true,
      pipelineError: null,
    })),
    switchMap((queryVars) => this.previewReportGQL.fetch(queryVars).pipe(tapResponse({
      next: (res) => {
        this.patchState(() => ({
          pipeline: res.data.previewReport.data.rows,
          pipelineLoading: false,
          lastReload: currentTimeSeconds(),
        }));
      },
      error: (err: ApolloError) => {
        this.patchState({
          pipelineLoading: false,
          pipelineError: getApolloErrorMessage(err),
        });
      },
    }))),
  ));

  readonly reloadAverageInvoiceSize = this.effect(() => this.reloadDataTrigger$.pipe(
    map(({ currency, start, end }) => this.getQueryVars({ start, end, currency, report: 'averageInvoiceSize' })),
    tap(() => this.patchState({
      averageInvoiceSizeLoading: true,
      averageInvoiceSizeError: null,
    })),
    switchMap((queryVars) => this.previewReportGQL.fetch(queryVars).pipe(tapResponse({
      next: (res) => {

        const updatedState: Partial<DashboardState> = {
          averageInvoiceSize: getDefaultAmount(),
          averageInvoiceSizeLoading: false,
          lastReload: currentTimeSeconds(),
        }

        const { rows: [ aggregations ] } = res.data.previewReport.aggregationData;

        if (aggregations) {
          const [ _discountedSubTotalSum, _count, discountedSubTotalAvg, currency ] = aggregations;

          updatedState.averageInvoiceSize = {
            amount: parseFloat(discountedSubTotalAvg),
            currency,
          }
        }
        this.patchState(() => (updatedState));
      },
      error: (err: ApolloError) => {
        this.patchState({
          averageInvoiceSizeLoading: false,
          averageInvoiceSizeError: getApolloErrorMessage(err),
        });
      },
    }))),
  ));

  readonly reloadPastAverageInvoiceSize = this.effect(() => this.reloadPastDataTrigger$.pipe(
    map(({ currency, start, end }) => this.getQueryVars({ start, end, currency, report: 'averageInvoiceSize' })),
    tap(() => this.patchState({ pastAverageInvoiceSizeLoading: true })),
    switchMap((queryVars) => this.previewReportGQL.fetch(queryVars).pipe(tapResponse({
      next: (res) => {

        const updatedState: Partial<DashboardState> = {
          pastAverageInvoiceSize: getDefaultAmount(),
          pastAverageInvoiceSizeLoading: false,
          lastReload: currentTimeSeconds(),
        }

        const { rows: [ aggregations ] } = res.data.previewReport.aggregationData;

        if (aggregations) {
          const [ _discountedSubTotalSum, _count, discountedSubTotalAvg, currency ] = aggregations;

          updatedState.pastAverageInvoiceSize = {
            amount: parseFloat(discountedSubTotalAvg),
            currency,
          }
        }
        this.patchState(() => (updatedState));
      },
      error: (err) => {
        console.error(err);
        this.patchState({ pastAverageInvoiceSizeLoading: false });
      },
    }))),
  ));

  readonly reloadSources = this.effect(() => this.reloadDataTrigger$.pipe(
    map(({ currency, start, end }) => this.getQueryVars({ start, end, currency, report: 'sources' })),
    tap(() => this.patchState({
      sourcesLoading: true,
      sourcesError: null,
    })),
    switchMap((queryVars) => this.previewReportGQL.fetch(queryVars).pipe(tapResponse({
      next: (res) => {
        this.patchState({
          sources: res.data.previewReport.data.rows,
          sourcesLoading: false,
          lastReload: currentTimeSeconds(),
        });
      },
      error: (err: ApolloError) => {
        this.patchState({
          sourcesLoading: false,
          sourcesError: getApolloErrorMessage(err),
        });
      },
    }))),
  ));

  readonly reloadEventsCreated = this.effect(() => this.reloadDataTrigger$.pipe(
    map(({ currency, start, end }) => this.getQueryVars({ start, end, currency, report: 'eventsCreated' })),
    tap(() => this.patchState({
      eventsCreatedLoading: true,
      eventsCreatedError: null,
    })),
    switchMap((queryVars) => this.previewReportGQL.fetch(queryVars).pipe(tapResponse({
      next: (res) => {
        const { headers, rows } = res.data.previewReport.data;
        this.patchState({
          eventsCreated: { headers, rows },
          eventsCreatedLoading: false,
          lastReload: currentTimeSeconds(),
        });
      },
      error: (err: ApolloError) => {
        this.patchState({
          eventsCreatedLoading: false,
          eventsCreatedError: getApolloErrorMessage(err),
        });
      },
    }))),
  ));

  readonly reloadEventsHappening = this.effect(() => this.reloadDataTrigger$.pipe(
    map(({ currency, start, end }) => this.getQueryVars({ start, end, currency, report: 'eventsHappening' })),
    tap(() => this.patchState({
      eventsHappeningLoading: true,
      eventsHappeningError: null,
    })),
    switchMap((queryVars) => this.previewReportGQL.fetch(queryVars).pipe(tapResponse({
      next: (res) => {
        const { rows, headers } = res.data.previewReport.data;

        this.patchState({
          eventsHappening: {
            headers: headers.length ? headers : placeholderEventHeaders,
            rows,
          },
          eventsHappeningLoading: false,
          lastReload: currentTimeSeconds(),
        });
      },
      error: (err: ApolloError) => {
        this.patchState({
          eventsHappeningLoading: false,
          eventsCreatedError: getApolloErrorMessage(err),
        });
      },
    }))),
  ));

  readonly reloadActivity = this.effect(() => this.reloadDataTrigger$.pipe(
    map(({ currency, start, end }) => {

      const queryVars: Parameters<typeof this.fetchActivity>[0] = {
        filter: this.mergeInDefaultFilters(currency),
        start,
        end,
        currency,
      };

      return queryVars;
    }),
    tap(() => this.patchState({
      activityLoading: true,
      activityError: null,
    })),
    switchMap((queryVars) => this.fetchActivity(queryVars).pipe(tapResponse({
      next: (res) => {
        this.patchState({
          transactionsPaidPerDay: res.data.transactionsPaid.data.rows,
          eventsCreatedPerDay: res.data.eventsCreated.data.rows,
          eventsHappeningPerDay: res.data.eventsHappening.data.rows,
          activityLoading: false,
          lastReload: currentTimeSeconds(),
        });
      },
      error: (err: ApolloError) => {
        this.patchState({
          activityLoading: false,
          activityError: getApolloErrorMessage(err),
        });
      },
    }))),
  ));

  readonly reloadRevenueAndLogistics = this.effect(() => this.reloadDataTrigger$.pipe(
    withLatestFrom(this.realizedBy$),
    map(([ { currency, start, end }, realizedBy ]) => this.getQueryVars({
      start,
      end,
      currency,
      realizedBy,
      report: 'revenueAndLogistics',
    })),
    tap(() => this.patchState({
      revenueAndLogisticsLoading: true,
      revenueAndLogisticsError: null,
    })),
    switchMap((queryVars) => this.previewReportGQL.fetch(queryVars).pipe(tapResponse({
      next: (res) => {

        const [ row ] = res.data.previewReport.data.rows;

        if (!row) {
          this.patchState({
            realizedRevenue: getDefaultAmount(),
            scheduledRevenue: getDefaultAmount(),
            expectedRevenue: getDefaultAmount(),
            distanceBooked: 0,
            distanceCompleted: 0,
            travelTimeBooked: 0,
            travelTimeCompleted: 0,
            revenueAndLogisticsLoading: false,
          });
          return;
        }

        const [
          realizedRevenueAmount,
          scheduledRevenueAmount,
          expectedRevenueAmount,
          distanceBooked,
          distanceCompleted,
          travelTimeBooked,
          travelTimeCompleted,
          currency,
        ] = row;

        this.patchState({
          realizedRevenue: {
            amount: parseFloat(realizedRevenueAmount),
            currency,
          },
          scheduledRevenue: {
            amount: parseFloat(scheduledRevenueAmount),
            currency,
          },
          expectedRevenue: {
            amount: parseFloat(expectedRevenueAmount),
            currency,
          },
          distanceBooked: parseFloat(distanceBooked),
          distanceCompleted: parseFloat(distanceCompleted),
          travelTimeBooked: parseFloat(travelTimeBooked),
          travelTimeCompleted: parseFloat(travelTimeCompleted),
          revenueAndLogisticsLoading: false,
          lastReload: currentTimeSeconds(),
        });
      },
      error: (err: ApolloError) => {
        this.patchState({
          revenueAndLogisticsLoading: false,
          revenueAndLogisticsError: getApolloErrorMessage(err),
        });
      },
    }))),
  ));

  readonly reloadPastRevenueAndLogistics = this.effect(() => this.reloadPastDataTrigger$.pipe(
    withLatestFrom(this.pastRealizedBy$),
    map(([ { currency, start, end }, realizedBy ]) => this.getQueryVars({
      start,
      end,
      currency,
      realizedBy,
      report: 'revenueAndLogistics',
    })),
    tap(() => this.patchState({ pastRevenueAndLogisticsLoading: true })),
    switchMap((queryVars) => this.previewReportGQL.fetch(queryVars).pipe(tapResponse({
      next: (res) => {

        const [ row ] = res.data.previewReport.data.rows;

        if (!row) {
          this.patchState({
            pastRealizedRevenue: getDefaultAmount(),
            pastScheduledRevenue: getDefaultAmount(),
            pastExpectedRevenue: getDefaultAmount(),
            pastDistanceBooked: 0,
            pastDistanceCompleted: 0,
            pastTravelTimeBooked: 0,
            pastTravelTimeCompleted: 0,
            pastRevenueAndLogisticsLoading: false,
          });
          return;
        }

        const [
          realizedRevenueAmount,
          scheduledRevenueAmount,
          expectedRevenueAmount,
          distanceBooked,
          distanceCompleted,
          travelTimeBooked,
          travelTimeCompleted,
          currency,
        ] = row;

        this.patchState({
          pastRealizedRevenue: {
            amount: parseFloat(realizedRevenueAmount),
            currency,
          },
          pastScheduledRevenue: {
            amount: parseFloat(scheduledRevenueAmount),
            currency,
          },
          pastExpectedRevenue: {
            amount: parseFloat(expectedRevenueAmount),
            currency,
          },
          pastDistanceBooked: parseFloat(distanceBooked),
          pastDistanceCompleted: parseFloat(distanceCompleted),
          pastTravelTimeBooked: parseFloat(travelTimeBooked),
          pastTravelTimeCompleted: parseFloat(travelTimeCompleted),
          pastRevenueAndLogisticsLoading: false,
          lastReload: currentTimeSeconds(),
        });
      },
      error: (err) => {
        console.error(err);
        this.patchState({ pastRevenueAndLogisticsLoading: false });
      },
    }))),
  ));

  readonly reloadLeadConversions = this.effect(() => this.reloadDataTrigger$.pipe(
    map(({ currency, start, end }) => this.getQueryVars({ start, end, currency, report: 'leadConversions' })),
    tap(() => this.patchState({
      leadConversionsLoading: true,
      leadConversionsError: null,
    })),
    switchMap((queryVars) => this.previewReportGQL.fetch(queryVars).pipe(tapResponse({
      next: (res) => {

        const updatedState: Partial<DashboardState> = {
          obeTotal: 0,
          obeConverted: 0,
          cscTotal: 0,
          cscConverted: 0,
          leadsTotal: 0,
          leadsConverted: 0,
          leadConversionsLoading: false,
          lastReload: currentTimeSeconds(),
        }

        const { rows } = res.data.previewReport.data;

        for (const row of rows) {
          const [ _jobOrigin, jobCount, convertedJobCount ] = row;
          updatedState.leadsTotal += parseInt(jobCount);
          updatedState.leadsConverted += parseInt(convertedJobCount);
        }

        const obeData = rows.find((row: string[]) => row[0] === 'OBE');
        const cscData = rows.find((row: string[]) => row[0] === 'CSC');

        if (obeData) {
          const [ _jobOrigin, total, converted ] = obeData;

          updatedState.obeTotal = parseInt(total);
          updatedState.obeConverted = parseInt(converted);
        }

        if (cscData) {
          const [ _jobOrigin, total, converted ] = cscData;

          updatedState.cscTotal = parseInt(total);
          updatedState.cscConverted = parseInt(converted);
        }

        this.patchState(updatedState);
      },
      error: (err: ApolloError) => {
        this.patchState({
          leadConversionsLoading: false,
          leadConversionsError: getApolloErrorMessage(err),
        });
      },
    }))),
  ));

  readonly reloadPastLeadConversions = this.effect(() => this.reloadPastDataTrigger$.pipe(
    map(({ currency, start, end }) => this.getQueryVars({ start, end, currency, report: 'leadConversions' })),
    tap(() => this.patchState({ pastLeadConversionsLoading: true })),
    switchMap((queryVars) => this.previewReportGQL.fetch(queryVars).pipe(tapResponse({
      next: (res) => {

        const updatedState: Partial<DashboardState> = {
          pastObeTotal: 0,
          pastObeConverted: 0,
          pastCscTotal: 0,
          pastCscConverted: 0,
          pastLeadsTotal: 0,
          pastLeadsConverted: 0,
          pastLeadConversionsLoading: false,
          lastReload: currentTimeSeconds(),
        }

        const { rows } = res.data.previewReport.data;

        for (const row of rows) {
          const [ _jobOrigin, jobCount, convertedJobCount ] = row;
          updatedState.pastLeadsTotal += parseInt(jobCount);
          updatedState.pastLeadsConverted += parseInt(convertedJobCount);
        }

        const obeData = rows.find((row: string[]) => row[0] === 'OBE');
        const cscData = rows.find((row: string[]) => row[0] === 'CSC');

        if (obeData) {
          const [ _jobOrigin, total, converted ] = obeData;

          updatedState.pastObeTotal = parseInt(total);
          updatedState.pastObeConverted = parseInt(converted);
        }

        if (cscData) {
          const [ _jobOrigin, total, converted ] = cscData;

          updatedState.pastCscTotal = parseInt(total);
          updatedState.pastCscConverted = parseInt(converted);
        }

        this.patchState(updatedState);
      },
      error: (err) => {
        console.error(err);
        this.patchState({ pastLeadConversionsLoading: false });
      },
    }))),
  ));

  readonly reloadOseConversion = this.effect(() => this.reloadDataTrigger$.pipe(
    map(({ currency, start, end }) => this.getQueryVars({ start, end, currency, report: 'oseConversion' })),
    tap(() => this.patchState({
      oseConversionLoading: true,
      oseConversionError: null,
    })),
    switchMap((queryVars) => this.previewReportGQL.fetch(queryVars).pipe(tapResponse({
      next: (res) => {

        let oseTotal = 0;
        let oseConverted = 0;

        const convertedStages = [ 'Booking', 'Invoice' ];

        for (const row of res.data.previewReport.data.rows) {
          const [ jobStage, jobCount ] = row;
          const count = parseInt(jobCount);
          oseTotal += count;
          if (!convertedStages.includes(jobStage)) { continue; }
          oseConverted += count;
        }

        this.patchState({
          oseTotal,
          oseConverted,
          oseConversionLoading: false,
          lastReload: currentTimeSeconds(),
        });
      },
      error: (err: ApolloError) => {
        this.patchState({
          oseConversionLoading: false,
          oseConversionError: getApolloErrorMessage(err),
        });
      },
    }))),
  ));

  readonly reloadPastOseConversion = this.effect(() => this.reloadPastDataTrigger$.pipe(
    map(({ currency, start, end }) => this.getQueryVars({ start, end, currency, report: 'oseConversion' })),
    tap(() => this.patchState({ pastOseConversionLoading: true })),
    switchMap((queryVars) => this.previewReportGQL.fetch(queryVars).pipe(tapResponse({
      next: (res) => {

        let pastOseTotal = 0;
        let pastOseConverted = 0;

        const convertedStages = [ 'Booking', 'Invoice' ];

        for (const row of res.data.previewReport.data.rows) {
          const [ jobStage, jobCount ] = row;
          const count = parseInt(jobCount);
          pastOseTotal += count;
          if (!convertedStages.includes(jobStage)) { continue; }
          pastOseConverted += count;
        }

        this.patchState({
          pastOseTotal,
          pastOseConverted,
          pastOseConversionLoading: false,
          lastReload: currentTimeSeconds(),
        });
      },
      error: (err) => {
        console.error(err);
        this.patchState({ pastOseConversionLoading: false });
      },
    }))),
  ));

  readonly resetCustomPeriod = this.effect(() => this.resetCustomPeriodTrigger$.pipe(
    filter((input) => input.period !== 'Custom'),
    tap((input: ExtractObservableType<typeof this.resetCustomPeriodTrigger$>) => {
      const { month, period } = input;
      if (period === 'Month of Year') {
        const monthDayJs = dayjs(month, 'MMMM YYYY');
        this.setCustomPeriod([
          monthDayJs.startOf('month').toDate(),
          monthDayJs.endOf('month').toDate(),
        ]);
      } else {
        this.setCustomPeriod(this.periodService.getPeriodAsDateArray(period));
      }
    }),
  ));

  readonly resetTicks = this.effect(() => this.activityViewModel$.pipe(
    tap((activity: ExtractObservableType<typeof this.activityViewModel$>) => {

      const ticks = transactionVolumeChartOptions.scales.y.ticks;

      const allValues = [
        ...activity.transactionsHappeningPerDay.map(([ _label, value ]) => parseFloat(value)),
        ...activity.eventsCreatedPerDay.map(([ _label, value ]) => parseFloat(value)),
        ...activity.eventsHappeningPerDay.map(([ _label, value ]) => parseFloat(value)),
      ];

      const min = Math.min(...allValues);
      const max = Math.max(...allValues);

      const finiteMin = isFinite(min) ? min : 1;
      const finiteMax = isFinite(max) ? max : 1;

      const nSteps = 4;

      // @ts-ignore, Doesn't match the updated type for v3 but seems to be backwards compatible
      ticks.min = finiteMin;

      // @ts-ignore, Doesn't match the updated type for v3 but seems to be backwards compatible
      ticks.stepSize = ((finiteMax - finiteMin) || 1) / nSteps;
    }),
  ));

  readonly resetCurrencyAndUnits = this.effect(() => this.branding.currentBranding.asObservable().pipe(
    map((branding) => {
      const countryCode = branding.country || environment.defaultCountry;
      return jurisdictions.find((j) => j.country === countryCode);
    }),
    tap((country: Country) => {
      this.patchState({
        currency: country?.currency || environment.defaultCurrency,
        units: country?.units || environment.defaultUnits,
      });
    }),
  ));

  readonly setQueryParams = this.effect(() => this.setQueryParamsTrigger$.pipe(
    skip(1),
    tap((input: ExtractObservableType<typeof this.setQueryParamsTrigger$>) => {

      let month: string | null = null;
      let comparedTo: string | null = null;
      let customPeriod: string | null = null;

      if (input.period === 'Month of Year') {
        month = input.month.replaceAll(' ', '-');
      } else if (input.period === 'Custom') {
        customPeriod = input.customPeriod.map((date) => dayjs(date).format('YYYY-MM-DD')).join(';');
      }

      if (input.period === 'Month of Year' || input.period === 'MTD') {
        comparedTo = input.comparedTo.replaceAll(' ', '-');
      }

      this.router.navigate([], {
        queryParams: {
          period: input.period.replaceAll(' ', '-'),
          month,
          comparedTo,
          customPeriod,
        }
      });
    }),
  ));

  readonly storeComparedToInLocalStorage = this.effect(() => this.comparedTo$.pipe(
    skip(1),
    tap((comparedTo: ExtractObservableType<typeof this.comparedTo$>) => {
      localStorage.setItem(comparedToLsKey, comparedTo);
    }),
  ));

  /**
   * Returns query variables for any report that serves as a data source for the dashboard.
   * Used by both dashboard widgets and their respective links to the underlying report,
   * ensuring that the link always points to the same data as the widget.
   */
  getQueryVars(input: {
    start: number;
    end: number;
    realizedBy?: number;
    currency: string;
    report: DashboardReport;
  }): PreviewReportQueryVariables {

    const { start, end, currency, report, realizedBy } = input;

    switch (report) {
      case 'pipeline':
        return {
          skip: 0,
          limit: -1,
          start,
          end,
          filter: this.mergeInDefaultFilters(currency, {
            job: {
              getJobsOfState: [ 'open' ],
            },
            event: {
              getEventsOfStatus: [],
            }
          }),
          periodType: 'jobCreatedAt',
          groupBy: 'jobStage',
          dataColumns: [ 'jobStage', 'jobCount' ],
          aggregationColumns: [],
          performance: 'skip-count',
          /**
           * We use 'web' here because 'sheets' forces the backend to resolve the currency,
           * which requires resolving events, which slows down the query significantly.
           */
          linkFormat: 'web',
        };
      case 'averageInvoiceSize':
        return {
          skip: 0,
          limit: -1,
          start,
          end,
          filter: this.mergeInDefaultFilters(currency, {
            job: {
              getJobsOfStage: [ 'invoice' ],
            },
          }),
          periodType: 'jobCreatedAt',
          groupBy: 'job',
          dataColumns: [ ],
          aggregationColumns: [
            'discountedSubTotalSum',
            'count',
            'discountedSubTotalAvg',
            'currency',
          ],
          performance: 'skip-data',
          // We do not want currency signs on amounts
          linkFormat: 'sheets',
        }
      case 'sources':
        return {
          skip: 0,
          limit: -1,
          start,
          end,
          filter: this.mergeInDefaultFilters(currency),
          periodType: 'jobCreatedAt',
          groupBy: 'source',
          dataColumns: [ 'source', 'jobCount', 'discountedSubTotal', 'currency' ],
          aggregationColumns: [],
          performance: 'skip-count',
          // We do not want currency signs on amounts
          linkFormat: 'sheets',
        };
      case 'eventsCreated':
        return {
          skip: 0,
          limit: -1,
          start,
          end,
          filter: this.mergeInDefaultFilters(currency),
          periodType: 'eventCreatedAt',
          groupBy: 'eventType',
          dataColumns: [
            'eventType',
            'discountedSubTotal',
            'eventCount',
            'averageDiscountedSubTotalPerEvent',
            'currency',
          ],
          aggregationColumns: [],
          performance: 'skip-count',
          linkFormat: 'sheets',
        };
      case 'eventsHappening':
        return {
          skip: 0,
          limit: -1,
          start,
          end,
          filter: this.mergeInDefaultFilters(currency),
          periodType: 'eventEnd',
          groupBy: 'eventType',
          dataColumns: [
            'eventType',
            'discountedSubTotal',
            'eventCount',
            'averageDiscountedSubTotalPerEvent',
            'currency',
          ],
          aggregationColumns: [],
          performance: 'skip-count',
          linkFormat: 'sheets',
      };
      case 'revenueAndLogistics':
        return {
          skip: 0,
          limit: -1,
          start,
          end,
          filter: this.mergeInDefaultFilters(currency),
          periodType: 'eventEnd',
          groupBy: 'none',
          dataColumns: [
            'realizedRevenue',
            'unrealizedRevenue',
            'discountedSubTotal',
            'distanceBooked',
            'distanceCompleted',
            'travelTimeBooked',
            'travelTimeCompleted',
            'currency',
          ],
          aggregationColumns: [],
          columnParams: {
            realizedBy,
          },
          performance: 'skip-count',
          linkFormat: 'sheets',
        };
      case 'leadConversions':
        return {
          skip: 0,
          limit: -1,
          start,
          end,
          filter: this.mergeInDefaultFilters(currency, {
            event: { getEventsOfStatus: [] },
          }),
          periodType: 'jobCreatedAt',
          groupBy: 'jobOrigin',
          dataColumns: [
            'jobOrigin',
            'jobCount',
            'convertedJobCount',
          ],
          aggregationColumns: [],
          performance: 'skip-count',
        };
      case 'oseConversion':
        return {
          skip: 0,
          limit: -1,
          start,
          end,
          filter: this.mergeInDefaultFilters(currency, {
            job: { hasCompletedEstimate: true },
            event: { getEventsOfStatus: [] }
          }),
          periodType: 'jobCreatedAt',
          groupBy: 'jobStage',
          dataColumns: [
            'jobStage',
            'jobCount',
          ],
          aggregationColumns: [],
          performance: 'skip-count',
        };
      case 'transactionsPaid':
        return {
          skip: 0,
          limit: -1,
          start,
          end,
          filter: this.mergeInDefaultFilters(currency),
          periodType: 'transactionPaidAt',
          groupBy: 'transactionPaidDate',
          dataColumns: [
            'transactionPaidDate',
            'transactionPaidTotal',
          ],
          aggregationColumns: [],
          performance: 'skip-count',
          linkFormat: 'sheets',
        };
      case 'eventsHappeningPerDay':
        return {
          skip: 0,
          limit: -1,
          start,
          end,
          filter: this.mergeInDefaultFilters(currency),
          periodType: 'eventStart',
          groupBy: 'eventBookingDate',
          dataColumns: [
            'eventBookingDate',
            'discountedSubTotal',
          ],
          aggregationColumns: [],
          performance: 'skip-count',
          linkFormat: 'sheets',
          sort: 'eventBookingDate ASC'
        };
      case 'eventsCreatedPerDay':
        return {
          skip: 0,
          limit: -1,
          start,
          end,
          filter: this.mergeInDefaultFilters(currency),
          periodType: 'eventStart',
          groupBy: 'eventCreatedAt',
          dataColumns: [
            'eventCreatedAt',
            'discountedSubTotal',
          ],
          aggregationColumns: [],
          performance: 'skip-count',
          linkFormat: 'sheets',
        };
      default:
      throw new Error(`Not handled: ${report}`);
    }
  }

  mergeInDefaultFilters(
    currency: string,
    additionalFilters?: PreviewReportQueryVariables['filter'],
  ): PreviewReportQueryVariables['filter'] {

    const defaultFilters = {
      job: {
        getJobsOfState: [ 'open', 'closed', 'archived' ],
        currency,
      },
      event: {
        getEventsOfStatus: [ 'required', 'pending', 'booked', 'confirmed', 'completed' ],
      },
    }

    if (!additionalFilters) {
      return defaultFilters;
    }

    // Do not merge arrays, just replace them
    const customizer = (objVal, srcVal) => {
      if (Array.isArray(objVal)) {
        return srcVal;
      }
    }

    // mergeWith mutates its first argument, clone to avoid bugs
    const clone = cloneDeep(defaultFilters);

    return mergeWith(clone, additionalFilters, customizer);
  }

  /**
   * Returns an observable of the query parameters
   * needed to navigate to the report that supplies the data for a given dashboard item.
   */
  getReportQueryParams(report: DashboardReport) {
    return this.select(
      this.currency$,
      this.unixPeriod$,
      // We include zone even though we do not use it to avoid memoizing solely based on currency and month
      this.graphqlModule.zone,
      (currency, { start, end }) => {

        const isPipeline = report === 'pipeline';

        const queryVars = this.getQueryVars({
          start: isPipeline ? getBeginningOfTimeUnix() : start,
          end: isPipeline ? getEndOfTimeUnix() : end,
          currency,
          realizedBy: end,
          report,
        });

        const formValue: { [ K in keyof ReportParamsForm['value'] ] : any }= {
          period: 'Custom',
          customPeriod: [
            new Date(start * 1000),
            new Date(end * 1000),
          ],
          periodType: queryVars.periodType,
          jobStage: queryVars.filter?.job?.getJobsOfStage,
          jobState: queryVars.filter?.job?.getJobsOfState?.length ? queryVars.filter?.job?.getJobsOfState : [ 'any' ],
          currency,
          jobHasCompletedEstimate: queryVars.filter?.job?.hasCompletedEstimate,
          eventStatuses: queryVars.filter?.event?.getEventsOfStatus?.length ? queryVars.filter?.event?.getEventsOfStatus : [ 'any' ],
          eventTypes: queryVars.filter?.event?.getEventsOfType,
          groupBy: queryVars.groupBy,
          sort: queryVars.sort,
          dataColumns: queryVars.dataColumns as string[],
          aggregationColumns: queryVars.aggregationColumns as string[],
          realizedBy: queryVars.columnParams?.realizedBy,
        }

        return this.queryParamsService.formatSearchFormValues(formValue, {
          dateRange: { controls: [ 'customPeriod' ]},
        });
      },
    );
  }

  getPercent(input: { total: number; converted: number }): number {
    return input.total ? ((input.converted / input.total) * 100) : 0;
  }

  getCurrencyDelta(input: { current: CurrencyAmount; past: CurrencyAmount }): Omit<CurrencyDelta, 'loading'> {
    const amount = Math.abs(input.past.amount - input.current.amount);
    const sign = input.past.amount === input.current.amount ? 'neutral' :
      input.past.amount > input.current.amount ? 'negative' : 'positive';
    return {
      amount,
      sign,
      currency: input.current.currency,
    };
  }

  getAmountDelta(input: { current: number; past: number }): Omit<AmountDelta, 'loading'> {
    const amount = Math.abs(input.past - input.current);
    const sign = input.past === input.current ? 'neutral' :
      input.past > input.current ? 'negative' : 'positive';
    return {
      amount,
      sign,
    };
  }

  fetchActivity(input: {
    start: number;
    end: number;
    filter?: PreviewReportFilter;
    currency: string;
  }) {
    return this.apollo.query<{
      transactionsPaid: { data?: any },
      eventsHappening: { data?: any },
      eventsCreated: { data?: any},
    }>({
      query: this.generateActivityQuery(input.currency),
      variables: { ...input },
    });
  }

  generateActivityQuery(currency: string) {

    const transactionsPaidQueryVars = this.getQueryVars({ start: 0, end: 0, currency, report: 'transactionsPaid' });
    const eventsHappeningQueryVars = this.getQueryVars({ start: 0, end: 0, currency, report: 'eventsHappeningPerDay' });
    const eventsCreatedQueryVars = this.getQueryVars({ start: 0, end: 0, currency, report: 'eventsCreatedPerDay' });

    return gql`
      query DashboardActivity(
        $start: Int!,
        $end: Int!,
        $filter: PreviewReportFilter,
        ) {
        transactionsPaid: previewReport(
          start: $start,
          end: $end,
          filter: $filter,
          groupBy: "${transactionsPaidQueryVars.groupBy}",
          periodType: "${transactionsPaidQueryVars.periodType}",
          dataColumns: [${(transactionsPaidQueryVars.dataColumns as string[]).map((col) => `"${col}"`).join(',')}],
          aggregationColumns: [${(transactionsPaidQueryVars.aggregationColumns as string[]).map((col) => `"${col}"`).join(',')}],
          ${transactionsPaidQueryVars.sort ? `sort: "${transactionsPaidQueryVars.sort}",` : ''}
          ${transactionsPaidQueryVars.performance ? `performance: "${transactionsPaidQueryVars.performance}",` : ''}
          ${transactionsPaidQueryVars.linkFormat ? `linkFormat: "${transactionsPaidQueryVars.linkFormat}",` : ''}
        ) {
          data
        }
        eventsHappening: previewReport(
          start: $start,
          end: $end,
          filter: $filter,
          groupBy: "${eventsHappeningQueryVars.groupBy}",
          periodType: "${eventsHappeningQueryVars.periodType}",
          dataColumns: [${(eventsHappeningQueryVars.dataColumns as string[]).map((col) => `"${col}"`).join(',')}],
          aggregationColumns: [${(eventsHappeningQueryVars.aggregationColumns as string[]).map((col) => `"${col}"`).join(',')}],
          ${eventsHappeningQueryVars.sort ? `sort: "${eventsHappeningQueryVars.sort}",` : ''}
          ${eventsHappeningQueryVars.performance ? `performance: "${eventsHappeningQueryVars.performance}",` : ''}
          ${eventsHappeningQueryVars.linkFormat ? `linkFormat: "${eventsHappeningQueryVars.linkFormat}",` : ''}
        ) {
          data
        }
        eventsCreated: previewReport(
          start: $start,
          end: $end,
          filter: $filter,
          groupBy: "${eventsCreatedQueryVars.groupBy}",
          periodType: "${eventsCreatedQueryVars.periodType}",
          dataColumns: [${(eventsCreatedQueryVars.dataColumns as string[]).map((col) => `"${col}"`).join(',')}],
          aggregationColumns: [${(eventsCreatedQueryVars.aggregationColumns as string[]).map((col) => `"${col}"`).join(',')}],
          ${eventsCreatedQueryVars.sort ? `sort: "${eventsCreatedQueryVars.sort}",` : ''}
          ${eventsCreatedQueryVars.performance ? `performance: "${eventsCreatedQueryVars.performance}",` : ''}
          ${eventsCreatedQueryVars.linkFormat ? `linkFormat: "${eventsCreatedQueryVars.linkFormat}",` : ''}
        ) {
          data
        }
      }
    `;
  }

  /**
   * Formats event currency and adds a total row to the end of the data.
   */
  formatEventData(data: ReportData) {

    const { headers, rows } = data;

    const currencyIndex = headers.indexOf('Currency');

    const formattedHeaders = headers.slice(0, currencyIndex);

    if (!data.rows.length) {
      return {
        headers: formattedHeaders,
        rows: [],
      }
    }

    const [ firstRow ] = rows;

    const currency = firstRow[currencyIndex];

    const formattedRows: string[][] = [];

    let subTotalSum = 0;
    let eventCountSum = 0;

    for (const [ eventType, netSubTotalRaw, eventCount, eventTypeAverageRaw ] of rows) {

      const netSubTotal = parseFloat(netSubTotalRaw);
      const eventTypeAverage = parseFloat(eventTypeAverageRaw);

      formattedRows.push([
        eventType,
        formatCurrency(netSubTotal, currency),
        eventCount,
        formatCurrency(eventTypeAverage, currency),
      ]);

      subTotalSum += netSubTotal;
      eventCountSum += parseInt(eventCount);
    }

    const average = eventCountSum ? subTotalSum / eventCountSum : 0;

    formattedRows.push([
      'Total',
      formatCurrency(subTotalSum, currency),
      eventCountSum.toString(),
      formatCurrency(average, currency),
    ]);

    return {
      headers: formattedHeaders,
      rows: formattedRows
    }
  }
}
