import { Injectable, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { PlusAuthenticationService } from '@karve.it/core';
import { TagsService } from '@karve.it/features';
import { Charge, ChargesInput } from '@karve.it/interfaces/charges';
import { BehaviorSubject, combineLatest, ReplaySubject, Subject } from 'rxjs';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { BaseCalendarEventFragment, BaseFieldFragment, BaseTagFragment, CalendarEventLocation, ChargeUpdate, EstimatesJobFragment, NewChargesInput } from '../../generated/graphql.generated';

import { DistanceService } from '../estimates/distance.service';
import { EstimateInfo, EstimatingSaveInfo, ModifiedField } from '../estimates/estimate.interfaces';
import { EventLocationInfo } from '../estimates/estimates.component';
import { JobInfoComponent } from '../estimates/job-info/job-info.component';
import { EventTypeInfo, eventTypeInfoMap, virtualEstimateDefaultTime, eventTypeTagStatuses, JobEventStatus, JobEventType, JOB_EVENT_TYPES, OriginEventBasicData } from '../global.constants';
import { getJobLocation } from '../jobs/jobs.util';
import { safeParseJSON } from '../js';
import { AddLocationFormType } from '../shared/add-location/add-location.component';

import { BrandingService } from './branding.service';
import { ChargeHelperService } from './charge-helper.service';
import { FreyaHelperService } from './freya-helper.service';
import { PermissionService } from './permission.service';
import { ProductMetadataValue } from './product-rules.service';

export type ChargeProductType = 'traveltime' | 'locationtime';

export interface EventInfoLocation {
  type: string;
  fullType: string;
  locationId?: string;
  estimatedTimeAtLocation: number;
  travelTimeToNextLocation?: number;
  distanceToNextLocation?: number;
  travelTimeToNextLocationOffset?: number;
  order: number;
}
export interface EventInfo {
  id?: string;
  eventType: string;
  eventTypeInfo: EventTypeInfo;
  locations: EventInfoLocation[];
  totalTime: number;
  totalLocationTime: number;
  totalTravelTime: number;
  totalDistanceMeters: number;
  baseLocationTypes: string[];
  includeStartDock: boolean;
  includeEndDock: boolean;
}

export interface EventTypeWithJobStatus {
  typeInfo: EventTypeInfo;
  status: JobEventStatus;
  tag?: BaseTagFragment;
  event?: BaseCalendarEventFragment;
}

export interface LocationMap {
  [locationType: string]: string;
};

export interface LocationSetInput {
  address: string;
  areaCode?: string;
}

@Injectable({
  providedIn: 'root'
})
export class EstimateHelperService implements OnDestroy {

  subs = new SubSink();

  estimateInfo: EstimateInfo = {
    bookingInfo: undefined,
    inventoryInfo: {},
    customerInfo: undefined,
    breakdownInfo: undefined,
    jobInfo: undefined,
    estimatedTotal: 0
  };

  // Fires when the restrictions have been enabled or disabled, True when enabled, false when disabled
  restrictionsEnabled = new BehaviorSubject<boolean>(true);

  estimateInfoUpdated = new Subject<EstimateInfo>();

  panelSync = new Subject<{ data: EstimateInfo }>();

  setStepIndex = new Subject<number>();

  // hangeEventStatus = new Subject<{ type: JobEventType; status: JobEventStatus }>();

  openConfirmationDialog = new Subject<boolean>();

  addressChanged = new Subject<void>();

  locationSet = new Subject<LocationSetInput>();

  jobLoading = new BehaviorSubject<boolean>(true);

  newJobCreated = false;

  permissionsAndJobLoading = combineLatest([
    this.jobLoading.pipe(distinctUntilChanged()),
    this.permissionHandler.watchPermissions([
      'jobs.update',
      'fields.setValue',
    ]),
  ]).pipe(
    (map(([ jobLoading, permissions ]) => ({
      jobLoading,
      updateJob: permissions[0],
      setFieldValues: permissions[1],
    }))),
  );

  fetchingNewJob = new Subject<boolean>();

  // List of the fields that have been modified and their values
  // modifiedFields = new Subject<ModifiedField>();

  // Stores the most recent fields values and is updated when the field values change
  fieldValues = new ReplaySubject<BaseFieldFragment[]>();

  fieldModified = new Subject<ModifiedField>();

  addRequiredEvent = new Subject<EventTypeInfo>();

  locationTabOpened$ = new Subject<AddLocationFormType>();

  eventTimeFromConfig = {
    estimating: undefined,
    virtualEstimate: undefined,
  }

  constructor(
    private distanceService: DistanceService,
    private tagService: TagsService,
    private chargeHelper: ChargeHelperService,
    private plusAuth: PlusAuthenticationService,
    private brandingService: BrandingService,
    private router: Router,
    private permissionHandler: PermissionService,
    private freyaService: FreyaHelperService,
  ) {
    this.subs.sink = this.plusAuth.authState.subscribe((state) => {
      if (state === 'deauthenticated') {
        this.subs.unsubscribe();
      }
    });
    this.subs.sink = this.freyaService.defaultEstimateLength.subscribe(value => {
      this.eventTimeFromConfig.estimating = value;
    });

    this.subs.sink = this.freyaService.defaultVirtualEstimateLength.subscribe(value => {
      this.eventTimeFromConfig.virtualEstimate = value;
    });

  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  assignFieldEventHandlers(
    subs: SubSink,
    fieldNames: string[],
    namespace: string,
    form: UntypedFormGroup,
  ) {
    for (const field of fieldNames) {
      subs.sink = form.controls[field]?.valueChanges.subscribe((val) => {

        const control = form.controls[field] as UntypedFormControl;

        const fieldKey = `${namespace}.${field}`;

        // If control was set back to its default value, remove field from modified
        if (val === control.defaultValue) {
          setTimeout(() => {
            this.fieldModified.next({
              name: fieldKey,
              discardChanges: true,
            } as ModifiedField);
          }, 0);

          return;
        }

        if (!control.dirty) { return; }
        // Emit event at end of event loop so that the value on the form gets a chance to update.
        // as valueChanges is called BEFORE the form value is updated.
        setTimeout(() => {
          this.fieldModified.next({
            name: fieldKey,
            value: val,
          } as ModifiedField);
          // outputEvent.emit();
        }, 0);
      });
    }
  }

  getLocationArrivalAndDepartureTimes(
    ce: BaseCalendarEventFragment,
  ) {
    const locations = ce.locations || [];

    let currentTime = ce.start;
    const newLocations: (BaseCalendarEventFragment['locations'][number] & {
      arrivalTime: number;
      departureTime: number;
      travelToNextDestination: number;
    })[] = [];

    for (const l of locations) {
      const arrivalTime = currentTime;
      currentTime += l.estimatedTimeAtLocation || 0;
      const departureTime = currentTime;
      const travelToNextDestination = (l.travelTimeToNextLocation) || 0 + (l.travelTimeToNextLocationOffset || 0);
      currentTime += travelToNextDestination;

      newLocations.push({
        ...l,
        arrivalTime,
        departureTime,
        travelToNextDestination,
      });
    }

    return newLocations;
  }

  /**
   * Non mutating - calculates the start and end time from the initial start and
   * total duration of the event
   *
   * @param eventInfo the event info
   * @param initialStart the unix time to start the event
   * @returns start and end in unix time, and the duration in seconds
   */
  calculateEventTiming(
    eventInfo: EventInfo,
    initialStart = 0,
  ) {
    let start = initialStart;

    let duration = 0;
    if (eventInfo?.eventTypeInfo?.virtualTime) {
      duration = eventInfo?.eventTypeInfo?.virtualTime;
    } else {

      const startIndex = eventInfo.locations.findIndex((v) => v.type === 'start');
      if (startIndex > 0) {
        // we have stops before the start, eg the dock
        const prelocations = eventInfo.locations.slice(0, startIndex);
        for (const loc of prelocations) {
          const offset = loc.estimatedTimeAtLocation + loc.travelTimeToNextLocation;
          start -= offset;
        }
      }

      duration = eventInfo.totalTime;
    }

    return {
      start,
      end: start + duration,
      duration,
    };
  }

  calculateBaseLocationTypes(
    includeStartDock = true,
    includeStart = true,
    includeEnd = true,
    includeEndDock = true,
  ) {
    const baseLocationTypes: string[] = [];

    if (includeStartDock) {
      baseLocationTypes.push('basestart');
    }
    if (includeStart) {
      baseLocationTypes.push('start');
    }
    if (includeEnd) {
      baseLocationTypes.push('end');
    }
    if (includeEndDock) {
      baseLocationTypes.push('baseend');
    }

    return baseLocationTypes;
  }

  /**
   * Determines charge product type
   *
   * @param charge
   * @returns
   */
  determineChargeProductType(
    charge: Charge,
  ): ChargeProductType {

    // Authomatic Charges/Rules
    const strAutoInfo = this.chargeHelper.getAutoInfoFromCharge(charge as any);
    if (strAutoInfo) {
      const autoInfo = safeParseJSON<ProductMetadataValue>(strAutoInfo);
      if (!autoInfo) {
      } else if (autoInfo.quantity === 'event-total-traveltime') {
        return 'traveltime';
      } else if (autoInfo.quantity === 'event-total-locationtime') {
        return 'locationtime';
      }
    }

    // Custom Charges (w/out products) don't apply
    if (!charge.product?.category) {
      return undefined;
    }

    // Check the category first as this is the cleaner way
    switch(charge.product?.category){
      case 'Truck & Travel':
        return 'traveltime';
      case 'Labor':
        return 'locationtime';
    }


    // Check the name if the category is not set, this way is deprecated and will be removed in the future
    const productName = (charge.product?.name || charge.productName)?.toLowerCase();

    if (charge.attributes?.includes('productType::traveltime')) {
      return 'traveltime';
    } else if (charge.attributes?.includes('productType::locationtime')) {
      return 'locationtime';
    } else if (charge.product?.attributes?.includes('productType::traveltime')) {
      return 'traveltime';
    } else if (charge.product?.attributes?.includes('productType::locationtime')) {
      return 'locationtime';
    } else if (productName.includes('man hour')) {
      return 'locationtime';
    } else if (productName.includes('person hour')) {
      return 'locationtime';
    } else if (productName.includes('crew hour')) {
      return 'locationtime';
    } else if (productName.includes('truck') && (productName.includes('travel'))) {
      return 'traveltime';
    }

    return undefined;
  }

  calculateChargesRelatedToEvent(
    allCharges: Charge[],
    eventId: string,
    productType: ChargeProductType,
  ) {

    const charges = [];
    let valueSum = 0;
    let quantitySum = 0;

    for (const charge of allCharges) {
      const chargeEventId = charge.calendarEvent?.id;
      if (chargeEventId !== eventId) { continue; }

      const chargeProductType = this.determineChargeProductType(charge);

      if (chargeProductType === productType) {
        charges.push(charge);
        quantitySum += charge.quantity;
        valueSum += charge.amount;
      }

    }

    return {
      charges,
      quantitySum,
      valueSum,
    };
  }

  isBaseLocation(
    l: EventInfoLocation
  ) {
    return l?.type === 'basestart' || l?.type === 'baseend' || l?.type === 'base';
  }

  setDefaultTimeAtLocation(
    location: EventInfoLocation,
    eventTypeInfo: EventTypeInfo,
  ) {
    const defaultLocationTypeDuration = eventTypeInfo.defaultLocationTimes[location.type];

    if (defaultLocationTypeDuration) {
      location.estimatedTimeAtLocation = defaultLocationTypeDuration;
    }
  }

  /**
   * Sums the estimatedTimeAtLocation property on a location
   */
  sumTimeAtEventLocations(
    locations: EventInfoLocation[],
  ) {

    return locations.reduce((p, l) => l.estimatedTimeAtLocation, 0);
  }

  /**
   * Update estimated time at each location and total time from charges.
   *
   * Includes time at location and estimated travel time
   *
   * @param eventInfo Event info, probably calculated from `precalculateEventInfo`
   * @param charges charges on a job to modify the estimated times based on changes in hours/travel
   */
  updateDurationsFromCharges(
    eventInfo: EventInfo,
    charges: Charge[],
  ) {
    const eventTypeInfo = eventTypeInfoMap[eventInfo.eventType];

    const determineCalculationForProduct = (
      productType: ChargeProductType,
      eventTime: number,
    ): {
      timeMultiplier: number;
      timeToAdd: number;
    } => {

      const {
        quantitySum: chargeTimeHours,
        charges: summedCharges,
      } = this.calculateChargesRelatedToEvent(charges, eventInfo.id , productType);
      const chargeTime = chargeTimeHours * 3600;

      if (
        // charge time and event time are both zero
        chargeTime === 0 && eventTime === 0
        // there are no summed charges
        && !summedCharges?.length
        // we are calculating the location time
        && productType === 'locationtime'
        // the total time at each location is zero
        && this.sumTimeAtEventLocations(eventInfo.locations) === 0
        // event type has a default location time
        && eventTypeInfo?.defaultLocationTimes
      ) {
        // missing both rules and charges, set location time from constants
        for (const location of eventInfo.locations) {
          this.setDefaultTimeAtLocation(location, eventTypeInfo);
        }

        return {
          timeMultiplier: 1,
          timeToAdd: 0,
        };
      }
      if (summedCharges.length === 0) {
        // use rule time because no charges were added.
        console.warn(`Not multiplying ${ productType } for ${ eventInfo.eventType }, no charges. Event time: ${ eventTime }`);

        return {
          timeMultiplier: 1,
          timeToAdd: 0,
        };
      }

      let addTime = 0;
      let multiple = chargeTime / eventTime;
      if (chargeTime === 0 && eventTime === 0) {
        // houston we have a problem
        console.warn(`cant calculate ${ eventInfo.eventType } ${ productType } `
          + `duration from charges, charge time and event time are 0`);

        return {
          timeMultiplier: 1,
          timeToAdd: 0,
        };
      } else if (eventTime === 0) {
        // use charge time by default
        addTime = chargeTime;
        multiple = 1;
      }

      return {
        timeMultiplier: multiple,
        timeToAdd: addTime,
      };
    };

    const {
      timeMultiplier: locationTimeMultiple,
      timeToAdd: locationTimeAdd,
    } = determineCalculationForProduct('locationtime', eventInfo.totalLocationTime);
    const {
      timeMultiplier: travelTimeMultiple,
      timeToAdd: travelTimeAdd,
    } = determineCalculationForProduct('traveltime', eventInfo.totalTravelTime);

    const nonBaseLocations = eventInfo.locations.filter((l) => !this.isBaseLocation(l));

    for (const l of eventInfo.locations) {
      // Set estimatedTimeAtLocation by multiplying the locationTimeMultiple and
      // adding additional time (spread across non base locations)
      l.estimatedTimeAtLocation = Math.ceil(
        l.estimatedTimeAtLocation * locationTimeMultiple
          + (this.isBaseLocation(l) ? 0 : locationTimeAdd / nonBaseLocations.length)
      );

      // Set offset based on the difference between the travel time to next location and
      // the travelTimeMultiple result plus the travel time to add (spread across non base locations)
      l.travelTimeToNextLocationOffset = Math.ceil(
        l.travelTimeToNextLocation * travelTimeMultiple - l.travelTimeToNextLocation
          + ( this.isBaseLocation(l) ? 0 : travelTimeAdd / nonBaseLocations.length)
      );
    }

    const res = this.calculateEventInfoTotals(eventInfo.locations);
    eventInfo.totalTime = res.totalTime;
    eventInfo.totalLocationTime = res.totalLocationTime + (eventTypeInfo.virtualTime || 0);
    eventInfo.totalTravelTime = res.totalTravelTime;
    eventInfo.totalDistanceMeters = res.totalDistanceMeters;

    //use for estimate and virtual estimate events, as their location time
    //being occasionally calculated incorrectly. here we explicitly override it
    // from config if it is provided
    this.overrideEventTimeFromConfig(eventInfo.eventTypeInfo, eventInfo);

    // console.log({
    //   locationTimeMultiple,
    //   travelTimeMultiple,
    //   locationTimeAdd,
    //   travelTimeAdd,
    //   eventInfo,
    // });

  }

  overrideEventTimeFromConfig(eventTypeInfo: EventTypeInfo, eventInfo: EventInfo) {
    if (eventTypeInfo.value === 'estimating' && this.eventTimeFromConfig.estimating) {
      // Display location time below Start Input
      eventInfo.totalLocationTime = this.eventTimeFromConfig.estimating;
      // Set duration when book
      eventInfo.totalTime = this.eventTimeFromConfig.estimating;
    }

    if (eventTypeInfo.value === 'virtualEstimate' && this.eventTimeFromConfig.virtualEstimate) {
      //Display location time below Start Input
      //and set duration when book for virtual estimate
      eventTypeInfo.virtualTime = this.eventTimeFromConfig.virtualEstimate;
    }

    if (eventTypeInfo.value === 'virtualEstimate' && !this.eventTimeFromConfig.virtualEstimate) {
      //when switching from zone with config set to zone without config set
      //reset virtualTime from virtualEstimateDefaultTime const instead of eventTypeInfoMap as in
      //precalculateEventInfo and updateDurationsFromCharges we set this obj to eventTypeInfo
      //and each time when mutate eventTypeInfo, eventTypeInfoMap is being mutates as well
      //as result, here eventTypeInfoMap.virtualEstimate.virtualTime contains overridden value, not default
      eventTypeInfo.virtualTime = virtualEstimateDefaultTime;
    }
  }

  calculateEventInfoTotals(locations: EventInfoLocation[]) {

    let totalLocationTime = 0;
    let totalTravelTime = 0;
    let totalDistanceMeters = 0;

    for (const l of locations) {
      totalLocationTime += l.estimatedTimeAtLocation || 0;
      totalTravelTime += l.travelTimeToNextLocation || 0;
      totalTravelTime += l.travelTimeToNextLocationOffset || 0;
      totalDistanceMeters += l.distanceToNextLocation || 0;
    }
    return {
      totalTime: totalLocationTime + totalTravelTime,
      totalLocationTime,
      totalTravelTime,
      totalDistanceMeters,
    };

  }

  /**
   * Uses the rule service and the distance service to precalculate
   * event information
   *
   * @param eventType the event type to calculate
   * @param locationTypeMap a map of location types to system location ID's
   * @returns an object with the event info
   */
  precalculateEventInfo(
    eventType: JobEventType,
    // map of ids by location type
    locationTypeMap: { [ locationType: string ]: string } = {},
    includeStartDock = true,
    includeEndDock = true,
  ): EventInfo {

    const eventTypeInfo = eventTypeInfoMap[eventType];

    const baseLocationTypes = eventTypeInfo.virtualTime ? [] : this.calculateBaseLocationTypes(
      includeStartDock,
      true,
      eventType === 'moving',
      includeEndDock,
    );

    const locations: EventInfoLocation[] = [];

    let order = 0;
    for (const baseLocationType of baseLocationTypes) {
      const locationType = `${ eventType }-${ baseLocationType }`;
      const estimatedTimeAtLocation = 0;

      let travelTimeToNextLocation = 0;
      let distanceToNextLocation = 0;
      const nextLocationType = baseLocationTypes[order + 1];
      if (nextLocationType) {
        const distance = this.distanceService.getDistanceBetweenLocationTypes(baseLocationType, nextLocationType);
        if (distance) {
          travelTimeToNextLocation = distance.estimatedTime;
          distanceToNextLocation = distance.totalDistance;
        }
      }

      locations.push({
        locationId: locationTypeMap[baseLocationType],
        fullType: locationType,
        type: baseLocationType,
        estimatedTimeAtLocation,
        travelTimeToNextLocation,
        distanceToNextLocation,
        travelTimeToNextLocationOffset: 0,
        order: order++,
      });
    }

    const eventInfoTotals = this.calculateEventInfoTotals(locations);
    // add virtual time to total location time
    eventInfoTotals.totalLocationTime += eventTypeInfo.virtualTime || 0;

    return {
      eventType,
      eventTypeInfo,
      baseLocationTypes,
      locations,
      includeStartDock,
      includeEndDock,
      ...eventInfoTotals,
    };
  }

  // Calculates how long the event is going to take
  calculateDurationOfEvent(eventType: JobEventType) {
    // there used to be rules here but I've removed them - Jared

    return 0;
  }

  async getTagIdSaveInfo(job: EstimatesJobFragment, requiredEvents: string[]) {
    const tagIds: string[] = [];
    const removeTagIds: string[] = [];
    const requiredEventsToAdd: EventTypeInfo[] = [];

    const eventTypes = this.getEventTypeStatusesOnJob(job, false);
    for (const requiredEvent of requiredEvents) {
      const eventInfo = eventTypes[requiredEvent];
      if (!eventInfo) { continue; }

      // determine if the event type exists as an event on the job. If it does, continue
      if (eventInfo.event) { continue; }

      // determine if the event type already has a tag on the job, if it does, continue
      if (eventInfo.status) { continue; }

      // add the event tag to tagsToAdd
      requiredEventsToAdd.push(eventInfo.typeInfo);
    };

    // if requiredEventsToAdd is non-empty then list the required tags for that event
    if (requiredEventsToAdd?.length) {
      // list required tags
      const { data: { tags: { tags: requiredEventTags } } } = await this.tagService.listTags({
        filter: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          categories_INCLUDES_ANY: requiredEventsToAdd.map((r) => r.tagCategory),
          names: [ 'Required' ],
          objectTypes: [ 'Job' ],
          // multiply by two just in case there is a duplicate (probably won't happen)
          limit: requiredEventsToAdd.length * 2,
        },
      }).toPromise();

      for (const t of requiredEventTags) {
        tagIds.push(t.id);
      }
    }


    // loop through pending tags - if there is a pending tag that is not in the list of
    // required events then remove that pending tag
    for (const tag of job?.tags || []) {
      const eventTypeInfo = Object.values(eventTypeInfoMap)
      .find((et) => et.tagCategory === tag.category);

      if (!eventTypeInfo) { continue; }
      if (requiredEvents.includes(eventTypeInfo.value)) { continue; }

      removeTagIds.push(tag.id);
    }

    return { tagIds, removeTagIds };
  }

  getEventTypeStatusesOnJob(job: EstimatesJobFragment, onlyEventsWithAStatus = false) {
    const tags = job?.tags || [];
    const events = job?.events || [];

    const eventTypes = [ ...JOB_EVENT_TYPES ];
    // add any additional event types
    for (const event of events) {
      if (eventTypes.includes(event.type)) { continue; }
      eventTypes.push(event.type);
    }

    const result: { [ eventType: string ]: EventTypeWithJobStatus } = {};
    for (const eventType of eventTypes) {
      const typeInfo = eventTypeInfoMap[eventType];
      // TODO: there may be more than one event of a certain type, but we are going to
      // ignore that for now.
      const existingEvent = events.find((e) => e.type === eventType);
      // const eventTagNamesByStatus = eventTypeStatusTagNames[eventType];
      // const category = eventType;

      let status = existingEvent ? existingEvent.status as JobEventStatus : undefined;

      const tagNames = Object.values(eventTypeTagStatuses);
      const tag = tags.find((t) => t.category === typeInfo.tagCategory && tagNames.includes(t.name));
      if (tag && !status) {
        status = tag.name.toLowerCase() as JobEventStatus;
      }

      if (status || !onlyEventsWithAStatus) {
        result[eventType] = {
          typeInfo,
          status,
          tag,
          event: existingEvent,
        };
      }
    }

    return result;
  }

  /**
   * Map location types to location IDs
   */
  async generateLocationTypeMap(job: EstimatesJobFragment) {
    const locationMap: LocationMap = {};

    // const dock = await this.freyaHelper.getDockLocation();

    const dock = getJobLocation(job, 'dock');

    locationMap.basestart = dock?.id;
    locationMap.baseend = dock?.id;

    locationMap.start = getJobLocation(job, 'start')?.id;
    locationMap.end = getJobLocation(job, 'end')?.id;


    return locationMap;

  }

  getEventLocationsThatNeedUpdating(
    estimatingSaveInfo: (void | EstimatingSaveInfo)[],
    job: EstimatesJobFragment,
    jobRef: JobInfoComponent,
  ) {
    const addLocationsSaveInfo = estimatingSaveInfo?.find((r) => r && r?.input?.addLocations?.length);

    if (!addLocationsSaveInfo) { return; }

    const eventsWithLocations = job?.events?.filter((e) => e?.locations?.length);

    if (!eventsWithLocations) { return; }

    const eventLocationsThatNeedUpdating: EventLocationInfo[] = [];

      for (const event of eventsWithLocations) {

        const eventLocations = event.locations as CalendarEventLocation[];

        const eventLocationInfo: EventLocationInfo = {
          event,
          locations: [],
        };

      for (const locationToAdd of addLocationsSaveInfo.input.addLocations) {
        // Get the location that we are trying to change on the job
        const currentJobLocation = job?.locations?.find((l) => l.locationType === locationToAdd.locationType)?.location;

        if (!currentJobLocation) { continue; }

        // Get all event locations whose type matches the type of the location we are trying to set on the job
        // e.g., if we are trying to set the job's start location, get the event's start location
        const eventLocationsOfMatchingType = this.getEventLocationsBasedOnJobLocationType(eventLocations, locationToAdd.locationType);

        if (!eventLocationsOfMatchingType.length) { continue; }

        for (const eventLocation of eventLocationsOfMatchingType) {

          // If the event's address does not match the address that we are trying to change on the job, it does not need updating
          if (eventLocation.location?.addressLineOne !== currentJobLocation?.addressLineOne) { continue; }

          // Pull the new location's address off the relevant location form
          const locationRef = jobRef.locationRefs?.find((ref) => ref.type === locationToAdd.locationType);
          const { address } = locationRef?.baseLocationForm.getRawValue();

          const newLocation: EventLocationInfo['locations'][number]['newLocation'] = { ...locationToAdd, address };

          eventLocationInfo.locations.push({
            currentLocation: eventLocation,
            newLocation,
          });
        }

      }

      if (eventLocationInfo.locations.length) {
        eventLocationsThatNeedUpdating.push(eventLocationInfo);
      }
    }

    return eventLocationsThatNeedUpdating;
  }

  getEventLocationsBasedOnJobLocationType(eventLocations: CalendarEventLocation[], jobLocationType: string) {

      if (!eventLocations?.length) {
          return eventLocations;
      }

      const eventLocationTypes: string[] = [];

      if (jobLocationType === 'dock') {
          eventLocationTypes.push('basestart', 'baseend');
      } else {
          eventLocationTypes.push(jobLocationType);
      }

      return eventLocations.filter((l) => eventLocationTypes.includes(l.type));
  }

  goToLocationsTab(locationType?: AddLocationFormType) {

    this.router.navigate([], {
      queryParams: { step: 'location' },
      queryParamsHandling: 'merge',
    });

    this.locationTabOpened$.next(locationType);
  }
}
