import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { RuleService } from '@karve.it/features';
import { BaseRuleFragment, CreateRuleInput, CreateRulesGQL, ListRulesQueryVariables } from 'graphql.generated';
import { pick } from 'lodash';
import { LazyLoadEvent } from 'primeng/api';
import { Table } from 'primeng/table';
import { SubSink } from 'subsink';

import { DetailsHelperService } from '../../services/details-helper.service';
import { FreyaNotificationsService } from '../../services/freya-notifications.service';
import { MutateRuleComponent } from '../../shared/mutate-rule/mutate-rule.component';

import { describeConditionObject } from './rules.constants';

@Component({
  selector: 'app-rules',
  templateUrl: './rules.component.html',
  styleUrls: ['./rules.component.scss']
})
export class RulesComponent implements OnInit, OnDestroy {

  @ViewChild('mutate') mutateRef: MutateRuleComponent;
  @ViewChild('rulesTable') rulesTable: Table;

  rules?: BaseRuleFragment[];
  totalRecords?: number;
  loading = true;

  watchQuery: ReturnType<typeof RuleService.prototype.watchRules>;

  skip = 0;
  limit = 10;

  private subs = new SubSink();

  constructor(
    private ruleService: RuleService,
    private dhs: DetailsHelperService,
    private localNotify: FreyaNotificationsService,
    private createRulesGQL: CreateRulesGQL
  ) { }

  ngOnInit(): void {

    this.subs.sink = this.dhs.getObjectUpdates('Rules').subscribe(() => {
      this.loadRules();
    });
  }

  ngOnDestroy(): void {
      this.subs.unsubscribe();
  }

  getVariables() {
    return {
      filter: {
      },
      limit: this.limit,
      skip: this.skip,
      sort: 'updatedAt:DESC',
    } as ListRulesQueryVariables;
  }

  reset() {
    this.loading = true;
    this.rules = [];
    this.totalRecords = undefined;
    this.loadRules();
  }

  loadRules(ev?: LazyLoadEvent) {
    this.loading = true;
    if (ev) {
      this.skip = ev.first;
      this.limit = ev.rows;
    }

    if (!this.watchQuery) {
      this.watchQuery = this.ruleService.watchRules(this.getVariables(), {
        fetchPolicy: 'cache-and-network',
      });

      this.subs.sink = this.watchQuery.valueChanges.subscribe((res) => {
        if (!res.data) { return; }
        this.totalRecords = res.data.rules.total;
        this.rules = res.data.rules.rules;
        this.loading = false;
      });
    } else {
      this.watchQuery.refetch(this.getVariables());
    }
  }

  selectRule(rule: BaseRuleFragment) {
    this.dhs.detailsItem.next({ type: 'rules', item: rule });
  }

  openCreate(){
    this.mutateRef.rule = undefined;
    this.mutateRef.mutateType = 'create';
    this.mutateRef.openDialog();
  }

  paste(event: ClipboardEvent) {

    event.preventDefault();

    try {
      if (!event.clipboardData) {
        throw new Error(`No clipboard data.`);
      }
      const clipboard = event.clipboardData.getData('text');
      console.log(clipboard);
  
      const rules: (BaseRuleFragment & {
        trigger: string;
        action: string;
        condition: string;
      })[] = JSON.parse(clipboard);
      console.log(rules);
      if (!rules || !Array.isArray(rules) || !rules?.length) {
        throw new Error(`Must be an array with rules in it`);
      }

      const rulesInput = rules.map((rule) => {
        if (rule.__typename !== 'Rule') {
          throw new Error(`Must be a rule.`);
        }

        return {
          properties: {
            ...pick(rule, [
              'trigger',
              'condition',
              'action',
              'attributes',
              'metadata',
              'delay',
              'delayUnit',
              'offset',
              'offsetUnit'
            ]),
          }
        } as CreateRuleInput;
      });

      this.subs.sink = this.createRulesGQL.mutate({
        rules: rulesInput,
      }).subscribe({
        next: (res) => {
          const createdRules = res.data?.createRules;
          this.localNotify.success(
            `${ createdRules.length } rule${ createdRules.length === 1 ? `s` : '' } created.`,
            `Rule opened in right panel`,
          );
          
          this.loadRules();

          // select the first rule
          const [ rule ] = createdRules;
          this.selectRule(rule);

        },
        error: (err) => {
          console.error(err);
          this.localNotify.error(`Error creating rules from clipboard.`, err.message);
        }
      });

    } catch (err) {
      this.localNotify.error(`Invalid format to create rule.`, 'See console');
    }
  }

  describeCondition(condition: string) {
    if (!condition || typeof condition !== 'string') { return ''; }
    return describeConditionObject(JSON.parse(condition));
  }

}
