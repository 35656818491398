<div>
    <div class="card estimating-header">
        <div class="p-grid">
            <div class="p-col-8 p-md-4 p-pb-0">
                <h4 data-cy="estimating-card-header" *ngIf="!job?.id && (!loading)">New Estimate</h4>
                <div *ngIf="job?.code && (!loading)">
                    <h4 class="job-title">
                        <a (click)="openCustomer()"
                            class="clickable"
                        >
                            {{ job.users | jobCustomer }}
                        </a>
                        <app-span-seperator></app-span-seperator>
                        <strong>
                            {{ job.stage | titlecase }}
                        </strong>
                        <app-object-state
                            [object]="job"
                            objectType="job"
                            [colorful]="true"
                            closureReasonPlacement="label"
                            [loading]="loading"
                        ></app-object-state>
                    </h4>
                    <div>
                        <a (click)="detailsHelper.open('job', { id: job.id })"
                            class="clickable"
                        >
                            {{ job.code }}
                        </a>
                        <ng-container *ngIf="job.zone">
                            <app-span-seperator></app-span-seperator>
                            <a *ngIf="job.zone"
                                [class.clickable]="job.zone.type === 'area'"
                                (click)="openZone()"
                                [pTooltip]="job.zone.type | titlecase"
                            >
                                {{ job.zone.name }}
                            </a>
                            <!-- show warning if not an area -->
                            <i class="pi pi-exclamation-triangle warning-color"
                                *ngIf="job.zone.type !== 'area'"
                                pTooltip="This job needs to be in an area to set charges and book events. Add a starting location to set the area."
                                [class.clickable]="!canSetJobZoneManually"
                                (click)="openSelectAreaDialog({ onlyShowCurrentSubzones: true })"
                            ></i>
                            <!-- show wrench  if restrictions are disabled or area is not a "perfect" match -->
                            <!-- cog, pencil, or wrench -->
                            <i class="pi pi-cog clickable"
                                *hasPermission="['fields.setValue']"
                                [class.disabled]="!canSetJobZoneManually"
                                (click)="openSelectAreaDialog({ onlyShowCurrentSubzones: false })"
                            ></i>
                        </ng-container>
                        <!-- <span>
                            {{ job.total | freyaCurrency : job.currency }}
                        </span> -->

                    </div>

                    <app-display-tags
                        #tags
                        [objectType]="'Job'"
                        [objectId]="job?.id"
                        [tagsOnObject]="job?.tags"
                        [readonly]="(hasSetTagsPermission$ | async) === false"
                    ></app-display-tags>
                </div>
                <div *ngIf="loading">
                    <p-skeleton
                        width="100%"
                        height="24px"
                    ></p-skeleton>
                </div>
            </div>
            <div class="p-col-4 p-md-8 p-pb-0 p-text-right" *hasPermission="['jobs.update']">
                <div>
                    <button
                        class="p-button p-m-1"
                        pButton
                        label="{{jobSaving ? 'Saving ...' : 'Save'}}"
                        [disabled]="!canSave()"
                        (click)="saveJobManually()"
                        icon="pi pi-save"
                        data-cy="save-button"
                        ></button>
                    <app-estimate-actions
                        [job]="job"
                        [disabled]="loading || jobSaving"
                        (newJobStarted)="reset()"
                        (jobClosed)="jobClosed = $event"
                        class="p-m-1 p-d-md-inline-flex"
                        ></app-estimate-actions>
                    <!-- <button
                        class="p-button-danger p-m-1 p-d-none p-d-md-inline-flex"
                        pButton
                        label="New Job"
                        (click)="openClearDialog()"
                        icon="pi pi-replay"
                        data-cy="new-job-button"
                    ></button>
                    <button
                        class="p-button-danger p-m-1 p-d-none p-d-md-inline-flex"
                        pButton
                        label="Close"
                        [disabled]="!job?.id || job?.stage === 'closed'"
                        (click)="openCloseDialog()"
                        icon="pi pi-circle-fill"
                        data-cy="close-button"
                    ></button>
                    <button
                        class="p-button-danger p-m-1 p-d-none p-d-md-inline-flex"
                        pButton
                        label="Delete"
                        [disabled]="!job"
                        (click)="openDeleteDialog()"
                        icon="pi pi-trash"
                    ></button>
                    <button
                        pButton
                        class="p-button-success p-m-1 p-d-none p-d-md-inline-flex"
                        *ngIf="!estimateHelper.restrictionsEnabled.value"
                        label="Re-Enable Restrictions"
                        icon="pi pi-check"
                        (click)="openRestrictionsDialog()"
                        data-cy="re-enable-restrictions-button"
                    ></button>
                    <button
                        pButton
                        class="p-button-warning p-m-1 p-d-none p-d-md-inline-flex"
                        *ngIf="estimateHelper.restrictionsEnabled.value"
                        label="Disable Restrictions"
                        icon="pi pi-ban"
                        (click)="openRestrictionsDialog()"
                        data-cy="disable-restrictions-button"
                    ></button> -->
                </div>
                <div
                *ngIf="job?.id"
                class="flex participants"
                >
                    <app-job-participant role="Sales Agent" [job]="job" [isLoading]="loading || jobSaving" ></app-job-participant>
                    <app-job-participant role="Estimator" [job]="job" [isLoading]="loading || jobSaving" ></app-job-participant>
                </div>
            <p-message
                severity="warn"
                text="Restrictions disabled"
                styleClass="p-m-1"
                *ngIf="(estimateHelper.restrictionsEnabled | async) === false">
            </p-message>
            </div>
        </div>
    </div>

    <div>
        <div
            [hidden]="!loading"
            class="loading-section"
        ></div>
        <div
            [hidden]="activeStep !== 0"
            class="card"
        >
            <app-customer-info
                #customerInfo
                (estimateUpdated)="onEstimateUpdated()"
            ></app-customer-info>
        </div>
        <div
            [hidden]="activeStep !== 1"
            class="card"
        >
            <app-job-info
                (startLocationSet)="onStartLocationSet($event)"
                (endLocationSet)="onEndLocationSet()"
                (fieldUpdated)="updateModifiedFields($event)"
                (estimateUpdated)="onEstimateUpdated()"
                #jobInfo
            ></app-job-info>
        </div>
        <div
            [hidden]="activeStep !== 2"
            class="card"
        >
            <app-estimating-inventory
                #inventory
                [job]="job"
                [roomsExpanded]="true"
                [readonly]="!(estimateHelper.permissionsAndJobLoading | async)?.setFieldValues"
                ></app-estimating-inventory>
            <!-- <app-estimate-inventory #inventory></app-estimate-inventory> -->
        </div>
        <div
            [hidden]="activeStep !== 3"
            class="card"
        >
            <app-estimate-breakdown
                (estimateUpdated)="onEstimateUpdated()"
                (createCustomCharge)="trySaveJob()"
                (chargesUpdated)="trySaveJob()"
                #breakdown
            ></app-estimate-breakdown>
        </div>
        <div [hidden]="activeStep !== 4">
            <app-job-booking #booking></app-job-booking>
        </div>
        <div [hidden]="activeStep !== 5">
            <app-estimate-confirmation
                [needsFinancing]="needsFinancing"
                [job]="job"
                [onEstimator]="true"
                #confirmation
                [printSectionId]="hostId"
            ></app-estimate-confirmation>
        </div>
    </div>

    <div
        class="card sticky-step"
        [ngClass]="{
            'sticky-step-keyboardopen': responsiveHelper.keyboardOpen,
            'sticky-step-safari': responsiveHelper.platform.SAFARI,
            'sticky-step-oldios': responsiveHelper?.iOSversion <= 14
        }"
    >
        <app-mini-steps
            class="p-d-md-none"
            [model]="steps"
            [(activeIndex)]="activeStep"
            (activeIndexChange)="indexChanged()"
        ></app-mini-steps>
        <div
            class="p-grid p-align-center p-jc-around p-d-none p-d-md-flex"
            style="overflow:auto; "
        >
            <div>
                <button
                    pButton
                    label="Prev"
                    icon="pi pi-arrow-left"
                    [disabled]="activeStep === 0 || loading || !canSave()"
                    (click)="activeStep = activeStep - 1; indexChanged()"
                ></button>
            </div>
            <div class="p-lg-10 p-md-12">
                <p-steps
                    [model]="steps"
                    [readonly]="loading"
                    [(activeIndex)]="activeStep"
                    (activeIndexChange)="indexChanged()"
                    [readonly]="!canSave()"
                    (click)="customerInfo.onStepsClicked()"
                ></p-steps>
            </div>
            <div>
                <button
                    pButton
                    iconPos="right"
                    label="Next"
                    icon="pi pi-arrow-right"
                    [disabled]="activeStep === 5 || loading || !canSave()"
                    (click)="activeStep = activeStep + 1; indexChanged()"
                ></button>
            </div>
        </div>
    </div>

    <div class="card">
        <div class="p-grid">
            <div class="p-col-12 p-md-6">
                <app-add-comment
                    *hasPermission="['comments.add']"
                    [nameForComment]="'note'"
                    [objectId]="this.job?.id"
                    [objectType]="'Job'"
                ></app-add-comment>
            </div>
            <div class="p-col-12 p-md-6">
                <h5>Estimated Total {{estimatedTotal / 100 | currency}}</h5>
                <small>Based on the currently selected items, does not include taxes or other fees.</small>
                <br>
                <small>Not representative of final price.</small>
                <br>
                <br>
                <div class="p-grid p-align-center p-pl-3 p-pt-1" *hasPermission="['fields.setValue']">
                    <p-inputSwitch
                        [(ngModel)]="needsFinancing"
                        (onChange)="handleFinancingChange()"
                    ></p-inputSwitch>
                    <span
                        class="p-ml-2"
                        style="display: inline-block;"
                    >Customer requires financing</span>
                </div>
            </div>
        </div>
    </div>

    <div class="p-mb-6">
        <!-- <app-estimate-comments [job]="job"></app-estimate-comments> -->
        <app-comments
            #comments
            [objectId]="this.job?.id"
            [objectType]="'Job'"
            [callOnLoad]="false"
            [nameForComment]="'Note'"
            [showAddButton]="false"
            [commentsFilter]="{hasNoAttributes: ['inventory', 'generated-inventory']}"
        ></app-comments>
    </div>

    <br>
    <br>

    <p-dialog
        [(visible)]="eventDialogVisible"
        [modal]="true"
        [style]="{width: '50vw'}"
        [breakpoints]="{'992px': '62vw', '510px': '93vw'}" 
    >
        <ng-template pTemplate="header">
            <h5>Pending Events
                <div *ngIf="!job?.users?.length || !job?.users[0].user">
                    <p-message
                        severity="warn"
                        text="Events cannot be booked until a customer has been set"
                    ></p-message>
                </div>
            </h5>
        </ng-template>
        <ng-template pTemplate="body">
            <p-table
                [value]="job.events | pendingEvents"
                responsiveLayout="scroll"
            >
                <ng-template pTemplate="header">
                    <tr>
                        <th>Type</th>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Action</th>
                    </tr>
                </ng-template>
                <ng-template
                    pTemplate="body"
                    let-ev
                >
                    <tr>
                        <td>{{ev.attributes[0] | titlecase}}</td>
                        <td>{{ev.start | freyaDate : 'EEEE, MMM d, y'}}</td>
                        <td>{{ev.start | freyaDate : 'h:mm a'}}</td>
                        <td>
                            <button
                                pButton
                                [disabled]="!job?.users?.length || !job?.users[0].user || eventsUpdating"
                                label="Book"
                                (click)="bookEvent(ev)"
                            ></button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td [attr.colspan]="'4'">
                            No Events require attention.
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <span class="p-text-left">
                Events shown here have been locked in the system preventing overlap bookings but have not yet been
                booked.
            </span>
        </ng-template>

        <ng-template pTemplate="footer">
        </ng-template>
    </p-dialog>

    <p-dialog
        [(visible)]="displayZoneSwitchError"
        [modal]="true"
        [style]="{width: '50vw'}"
        [breakpoints]="{'992px': '62vw', '510px': '93vw'}" 
    >
        <ng-template pTemplate="header">
            <h5>Location Area Problem</h5>
        </ng-template>
        <ng-template pTemplate="body">
            <p>The entered location belongs to another area. Please Create a new Job for this location.</p>
        </ng-template>

        <ng-template pTemplate="footer">
            <button
                pButton
                label="Okay"
                (click)="displayZoneSwitchError = false"
            ></button>
        </ng-template>
    </p-dialog>

    <p-dialog
        [(visible)]="changeZoneDialogVisible"
        [modal]="true"
        class="change-zone-dialog"
        [style]="{width: '50vw'}"
        [breakpoints]="{'992px': '62vw', '510px': '93vw'}" 
        (onHide)="confirmationHidden($event, changeZoneConfirmationOptions)"
        [closeOnEscape]="!changeZoneConfirmationOptions?.requireAnswer"
        [class.requireAnswer]="changeZoneConfirmationOptions?.requireAnswer"
    >
        <ng-template pTemplate="header">
            <h5>{{ changeZoneConfirmationOptions.header }}</h5>
        </ng-template>
        <ng-template pTemplate="body">
            <p
                class="zone-confirm-address"
                *ngIf="changeZoneConfirmationOptions.location && changeZoneConfirmationOptions.showAddress"
            >
                <strong>Address</strong>
                <span>{{ changeZoneConfirmationOptions.location.address }}</span>
            </p>
            <p class="zone-message">{{ changeZoneConfirmationOptions.message }}</p>
            <p *ngIf="changeZoneConfirmationOptions.note"><strong>Note:</strong> {{changeZoneConfirmationOptions.note}}
            </p>
            <p
                class="zone-message"
                *ngIf="changeZoneConfirmationOptions.nextMessage"
            ><strong>{{ changeZoneConfirmationOptions.nextMessage }}</strong></p>
        </ng-template>
        <ng-template pTemplate="footer">
            <button
                pButton
                class="p-button-warning p-button-sm"
                [disabled]="disableChangeZoneDialogButtons"
                *ngIf="changeZoneConfirmationOptions.rejectVisible !== false"
                [label]="changeZoneConfirmationOptions.rejectLabel || 'No'"
                (click)="changeZoneConfirmationOptions.reject ? changeZoneConfirmationOptions.reject($event) : undefined"
            ></button>
            <button
                pButton
                [disabled]="disableChangeZoneDialogButtons"
                *ngIf="changeZoneConfirmationOptions.acceptVisible !== false"
                [label]="changeZoneConfirmationOptions.acceptLabel || 'Yes'"
                (click)="changeZoneConfirmationOptions.accept ? changeZoneConfirmationOptions.accept($event) : undefined"
            ></button>
        </ng-template>
    </p-dialog>

    <ng-template #elseBlock>
        <p-skeleton
            width="100%"
            height="24px"
        ></p-skeleton>
    </ng-template>
</div>
