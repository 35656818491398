/* eslint-disable @typescript-eslint/naming-convention */
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ConfigService, PlusAuthenticationService, dayjs } from '@karve.it/core';
import { TagsService } from '@karve.it/features';
import { Field } from '@karve.it/interfaces/fields';

import { lastValueFrom } from 'rxjs';
import { CUSTOMER_TYPES, DEFAULT_HOW_HEARD_DROPDOWN_OPTIONS, JOB_ORIGINS, JOB_ROLE_MAP, TAG_CATEGORIES } from 'src/app/global.constants';
import { EstimateHelperService } from 'src/app/services/estimate-helper.service';
import { FreyaHelperService } from 'src/app/services/freya-helper.service';
import { FreyaNotificationsService } from 'src/app/services/freya-notifications.service';
import { CreateUserGQL, CreateUserMutationVariables, EstimatesJobFragment, ExistingCustomerJobsGQL, FullUserFragment, GetConfigValuesGQL, ListFullUsersWithoutCountGQL, ListFullUsersWithoutCountQueryVariables } from 'src/generated/graphql.generated';
import { SubSink } from 'subsink';

import { arrPushOrInit } from '../../js';
import { FreyaMutateService } from '../../services/freya-mutate.service';
import { Address, GoogleHelperService } from '../../services/google-helper.service';
import { EMAIL_VALIDATION_PATTERN } from '../../shared/pattern.validator';
import { MS_ONE_DAY } from '../../time';
import { getParsedConfigValueByKey } from '../../utilities/configs.util';
import { EstimateUpdated, EstimateUpdatedEvent, EstimatingSaveInfo, ModifiedField } from '../estimate.interfaces';

@Component({
  selector: 'app-customer-info',
  templateUrl: './customer-info.component.html',
  styleUrls: ['./customer-info.component.scss']
})
export class CustomerInfoComponent implements OnInit, OnDestroy, EstimateUpdated {

  @Output() fieldUpdated = new EventEmitter<ModifiedField>();
  @Output() estimateUpdated = new EventEmitter<EstimateUpdatedEvent>();

  @Input() job: EstimatesJobFragment;

  subs = new SubSink();

  customerType = 'create'; // The type of customer, either creating new or selecting existing

  userSearchResults: FullUserFragment[]; // The users returned by the most recent search.

  usersQueryRef: ReturnType<typeof this.listUsersGQL.watch>;

  customerFields: Field[] = []; // List of the fields used in this component

  existingInvoices: { code?: string; id: string }[];

  permissions = {
    setFieldValue: false,
    updateJob: false,
  };

  jobLoading = false;

  billingAddress: Address;

  customerForm = new FormGroup({
    userId: new FormControl('', []),
    company: new FormControl('', []),
    firstName: new FormControl('', [Validators.required, Validators.pattern(/[a-zA-Z0-9]+/)]),
    lastName: new FormControl('', [Validators.required, Validators.pattern(/[a-zA-Z0-9]+/)]),
    billingAddress: new FormControl(''),
    phone: new FormControl('', [Validators.minLength(7)]),
    email: new FormControl('', [Validators.pattern(EMAIL_VALIDATION_PATTERN)]),
    // Customer History Info
    // usedUsBefore: new FormControl(false, []),
    // usedOtherBefore: new FormControl(false, []),
    howDidTheyHearAboutUs: new FormControl(undefined, { validators: [] }),
    type: new FormControl(CUSTOMER_TYPES[0], []),
    origin: new FormControl(JOB_ORIGINS[0], []),
    existingLead: new FormControl(false, []),
    timeline: new FormControl([] as [Date, Date] | [], [])
  });

  customerTypes = CUSTOMER_TYPES;
  howHeardDropdownOptions = DEFAULT_HOW_HEARD_DROPDOWN_OPTIONS;
  howHeardMandatory = false;
  jobOrigins = JOB_ORIGINS;
  searchActions = [{
    label: 'Search By',
    items: [
      {
        label: 'Name',
        command: () => {
          this.searchForUsers('name');
        }
      },
      {
        label: 'Email',
        command: () => {
          this.searchForUsers('email');
        }
      },
      {
        label: 'Phone',
        command: () => {
          this.searchForUsers('phone');
        }
      }
    ]
  }];


  // The user we have selected for this job
  selectedUser: FullUserFragment;

  constructor(
    private listUsersGQL: ListFullUsersWithoutCountGQL,
    private createUserGQL: CreateUserGQL,
    public estimateHelper: EstimateHelperService,
    private localNotify: FreyaNotificationsService,
    private freyaHelper: FreyaHelperService,
    private configService: ConfigService,
    private plusAuth: PlusAuthenticationService,
    private freyaMutate: FreyaMutateService,
    private tagService: TagsService,
    private existingCustomerJobsService: ExistingCustomerJobsGQL,
    private getConfigValues: GetConfigValuesGQL,
    public googleHelper: GoogleHelperService,
  ) {
    this.isValidBillingAddress = this.isValidBillingAddress.bind(this);
  }

  ngOnInit(): void {
    const customerFields = ['howDidTheyHearAboutUs'];
    this.estimateHelper.assignFieldEventHandlers(this.subs, customerFields, 'customer', this.customerForm);

    this.subs.sink = this.customerForm.valueChanges.subscribe((change) => {
      this.estimateUpdated.next({});
    });

    this.subs.sink = this.customerForm.controls.howDidTheyHearAboutUs.valueChanges
      .subscribe((val) => {

        // Upon changing the value of `howHeardDropdownOptions`, the PrimeNG dropwdown will set this control to `null`
        // When that happens, we need to reset it to its default value
        // so that the estimate component knows that the field has not actually been modified
        // otherwise it will try to set the field to null, causing an error
        if (val === null) {
          this.customerForm.controls.howDidTheyHearAboutUs.reset(undefined, { emitEvent: false });
        }
      });

    this.setPermissions();

    this.getConfigValues.fetch({
      keys: [
        'jobs.howHeardOptions',
        'jobs.howHeardMandatory',
        'jobs.origins',
        'customer.types',
      ]
    }).subscribe((res) => {

      const values = res.data.getConfigValues;

      const howHeardMandatory = values.find((c) => c.key === 'jobs.howHeardMandatory');

      this.howHeardMandatory = howHeardMandatory?.value === 'true';

      if (this.howHeardMandatory) {
        this.customerForm.get('howDidTheyHearAboutUs').setValidators([
          Validators.required,
        ]);

        // required after updating the validators
        this.customerForm.get('howDidTheyHearAboutUs').updateValueAndValidity();
      } else {
        this.customerForm.get('howDidTheyHearAboutUs').setValidators([]);

        // required after updating the validators
        this.customerForm.get('howDidTheyHearAboutUs').updateValueAndValidity();
      }

      this.howHeardDropdownOptions = getParsedConfigValueByKey(values, 'jobs.howHeardOptions', DEFAULT_HOW_HEARD_DROPDOWN_OPTIONS);
      this.jobOrigins = getParsedConfigValueByKey(values, 'jobs.origins', JOB_ORIGINS);
      this.customerTypes = getParsedConfigValueByKey(values, 'customer.types', CUSTOMER_TYPES);

      this.updateOptions();
    });

    this.customerForm.get('billingAddress').setValidators(this.isValidBillingAddress);
  }

  setPermissions() {
    this.subs.sink = this.estimateHelper.permissionsAndJobLoading.subscribe((res) => {
      this.permissions.updateJob = res.updateJob;
      this.permissions.setFieldValue = res.setFieldValues;
      this.jobLoading = res.jobLoading;
      this.setCustomerFormDisabled();
    });
  }

  setCustomerFormDisabled() {

    if ((this.jobLoading && this.job) || !this.permissions.updateJob) {
      this.customerForm.disable();
      return;
    }

    // enable everything so we can now selectively disable things
    this.customerForm.enable();

    this.freyaHelper.setDisabledControls(!this.selectedUser?.id, this.customerForm, [
      'firstName',
      'lastName',
      'email',
      'phone',
      'company',
      'billingAddress',
    ]);

    this.freyaHelper.setDisabledControls(this.permissions.updateJob, this.customerForm, ['timeline']);

    this.freyaHelper.setDisabledControls(this.permissions.setFieldValue, this.customerForm, [
      // 'usedUsBefore',
      // 'usedOtherBefore',
      'howDidTheyHearAboutUs',
      'type',
      'origin',
    ]);
  }

  getTimeline(): { timeline: string; timelineDays: number } {
    const [start, end] = this.customerForm.value?.timeline || [];

    if (!start) {
      return {
        timeline: undefined,
        timelineDays: undefined,
      };
    }

    let timelineDays = 0;
    if (start && end) {
      const diff = (end.getTime() - start.getTime()) / MS_ONE_DAY;

      timelineDays = Math.floor(diff);
      if (timelineDays < 0) {
        timelineDays = 0;
      }
    }


    // I can't get formatting with moment to work correctly ):
    const timeline = [
      `${start.getFullYear()}`,
      `${(start.getMonth() + 1).toString().padStart(2, '0')}`,
      `${start.getDate().toString().padStart(2, '0')}`,
    ].join('-');

    return {
      timeline,
      timelineDays,
    };

  }

  setTimelineIfNotSet(strDate: string) {
    if (!strDate) { return; }

    const { timeline } = this.getTimeline();

    if (timeline) { return; }

    const control = this.customerForm.get('timeline');

    control.setValue(this.timelineStringToArray(strDate, 0));

    control.markAsDirty();
  }

  setHowDidYouHearAboutUs(value: string) {
    if (!value) {
      value = undefined;
      // value = this.howHeardDropdownOptions[0];
    }

    this.customerForm.controls.howDidTheyHearAboutUs.setValue(value);

    this.updateOptions();
  }

  ngOnDestroy() {
    delete this.usersQueryRef;
    this.subs.unsubscribe();
  }

  async setCustomer(activeJob: EstimatesJobFragment) {
    this.job = activeJob;

    if (!this.job) { return; }

    const typeValue = this.job.metadata?.jobType;
    const jobOrigin = this.job.metadata?.jobOrigin;

    const user = activeJob.users?.find((u) => u.role === JOB_ROLE_MAP.customerRole)?.user;
    if (!user) { return; }

    const timeline = this.timelineStringToArray(activeJob.timeline, activeJob.timelineDays);

    // TODO: Default the form to the last customer value
    this.customerForm.patchValue({
      userId: user.id,
      company: user.company,
      firstName: user.givenName,
      lastName: user.familyName,
      phone: user.phone,
      email: user.email,
      type: typeValue || CUSTOMER_TYPES[0],
      origin: jobOrigin || JOB_ORIGINS[0],
      billingAddress: user.billingAddress?.addressLineOne,
      timeline,
    });

    if (user.billingAddress) {
      this.billingAddress = this.googleHelper.convertLocationToGoogleAddress(user.billingAddress);
    }

    this.customerForm.markAsPristine();

    this.customerType = user.id;
    this.selectedUser = user;
    this.setCustomerFormDisabled();
  }

  reset() {
    this.subs.unsubscribe();
    delete this.usersQueryRef;
    this.job = undefined;
    this.customerForm.reset();
  }

  async setJobOrigin() {
    //TODO: This function can be written better
    const [role] = await this.freyaHelper.getRoles({ ids: [this.plusAuth.contextedRoleId] });

    let attribute = this.freyaHelper.getAttributeValueByPrefix(role.attributes, 'jobOrigin');

    // If we couldn't match the prefix we are expecting then check the names
    if (!attribute) {
      if (role.name.includes('Employee') || role.name.includes('Franchisee')) {
        attribute = 'FP';
      } else if (role.name.includes('Call Center')) {
        attribute = 'CSC';
      }
    }

    if (attribute) {
      this.customerForm.controls.origin.setValue(attribute);

    } // Else - Default is CSC

    this.updateOptions();
  }
  hasChanges() {
    return this.customerForm.dirty;
  }

  // Updates the user or creates the user an adds the id to the input
  async saveCustomer() {
    const metadata = {
      jobType: this.customerForm.getRawValue().type || undefined,
      jobOrigin: this.customerForm.getRawValue().origin || undefined,
    };

    const saveInfo: EstimatingSaveInfo = {
      input: { jobId: undefined },
      promises: [],
      metadata,
    };

    const timelineControl = this.customerForm.get('timeline');
    if (!timelineControl.pristine) {
      timelineControl.markAsPristine();
      const { timeline, timelineDays } = this.getTimeline();
      saveInfo.input.timeline = timeline;
      saveInfo.input.timelineDays = timelineDays;
    }

    // Set Lead tag to save if we have set it for the first time
    if (this.customerForm.controls.existingLead.touched) {
      const existingLeadTagId = await this.getTagForLead(this.customerForm.getRawValue().existingLead);
      saveInfo.tagIds = arrPushOrInit(saveInfo.tagIds, existingLeadTagId);
      this.customerForm.controls.existingLead.markAsPristine();
    }

    //TODO: Add Store the job-type on the customer

    if (this.customerForm.dirty && this.customerForm.getRawValue().userId) {
      this.customerForm.markAsPristine();
      // saveInfo.promises.push(this.setProfile()); // Add it to the list of calls

      // If the user exists but hasn't been added to the job
      if (!this.job?.users?.find((u) => u.user?.id === this.customerForm.getRawValue().userId)) {
        saveInfo.input.addUsers = [{
          role: JOB_ROLE_MAP.customerRole,
          userId: await this.setUser(),
        }];
      }
    } else if (this.customerForm.valid && this.customerForm.dirty && !this.customerForm.getRawValue().userId) {
      saveInfo.input.addUsers = [{
        role: JOB_ROLE_MAP.customerRole,
        userId: await this.setUser(),
      }];
    }

    return saveInfo;
  }

  /**
   * Get the ID for the Lead tag depedning on if the customer exists or not
   *
   * @param exists If the customer already exists
   * @returns The id for which lead tag should be applied
   */
  async getTagForLead(exists: boolean): Promise<string> {
    return new Promise(async (resolve, reject) => {
      this.subs.sink = this.tagService.listTags({
        filter: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          categories_INCLUDES_ANY: [TAG_CATEGORIES.lead],
          names: [exists ? 'Existing' : 'New']
        }
      }).subscribe((res) => {
        if (!res.data?.tags?.tags?.length) {
          console.error('lead tag could not be found');
          return reject('lead tag could not be found');
        }
        resolve(res.data?.tags?.tags[0].id);
      }, (err) => {
        console.error('lead tag request failed');
        reject('lead tag request failed');
      });
    });
  }


  // async setProfile() {
  //   const val = this.customerForm.value;
  //   return new Promise<boolean>((resolve, reject) => {
  //     const phone = val.phone && this.customerForm.controls.phone.valid ? val.phone : undefined;
  //     if (val.phone && !phone) {
  //       this.localNotify.warning('Phone number was not updated, please check it is a valid number and try again');
  //     }
  //     this.subs.sink = this.userService.setProfile(
  //       {
  //         id: val.userId,
  //         familyName: val.lastName,
  //         givenName: val.firstName,
  //         phone
  //       }).subscribe((res) => {
  //         resolve(true);
  //       }, (err) => {
  //         this.localNotify.addToast.next({ severity: 'error', summary: 'Failed to update user' });
  //         reject(err);
  //       });
  //   });

  // }

  // Sets the user on the job whether they are existing or need to be created, resolves the userId
  async setUser() {
    const { userId, email } = this.customerForm.getRawValue();

    // If the user is selected from list (do nothing)
    if (userId) {
      this.setExistingLead(false);
      return userId;
    }

    // If user is entered by email (check if user exists)
    if (email) {
      // Check if customer exists
      const { data: { usersv2: { users } } } = await lastValueFrom(
        this.listUsersGQL.fetch({
          filter: {
            emails: [email],
          }
        }),
      );

      const resolvedUser = users[0];

      if (resolvedUser) {
        this.selectedUser = resolvedUser;
        this.customerForm.controls.userId.setValue(this.selectedUser.id);
        this.localNotify.warning('Email matched to existing user', 'please verify the details are correct');
        this.setExistingLead(true);
        return this.selectedUser.id;
      }
    }

    // Create a new customer
    this.setExistingLead(false);
    return this.createNewUser();
  }

  async createNewUser(info = this.customerForm.getRawValue()) {
    // Get the customer role for the appropriate zone
    const role = await this.getCustomerRoleId();

    const userInput: CreateUserMutationVariables = {
      users: [{
        email: info.email ? info.email : undefined,
        givenName: info.firstName ? info.firstName : undefined,
        familyName: info.lastName ? info.lastName : undefined,
        company: info.company ? info.company : undefined,
        phone: info.phone ? info.phone : undefined,
        billingAddress: this.billingAddress ? this.googleHelper.convertGoogleLocationToCreateLocationInput(this.billingAddress) : undefined,
      }],
      roles: [role],
      inheritRoleZones: true,
    };

    return new Promise<string>((resolve, reject) => {
      this.createUserGQL.mutate(userInput).subscribe((res) => {
        const { data: { createUsers } } = res;
        const [user] = createUsers.users || [];
        if (!user) {
          console.error(createUsers.failedUsers);
          reject(`Failed to create user ${createUsers?.failedUsers?.length} `);
          return;
        }

        this.customerForm.controls.userId.setValue(user.id);
        this.customerForm.markAsPristine();
        resolve(user.id);
      }, (err) => {
        reject(err.message);
      });
    });
  }

  /**
   * Get the appropriate customer role ID based on the config values
   */
  async getCustomerRoleId(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.subs.sink = this.configService.getConfigValues({ keys: ['role.customer'] }).subscribe({
        next: (res) => {
          const config = res.data.getConfigValues[0];
          if (!config) {
            console.error(`Could not resolve config for customer role`);
            return reject(new Error(`Could not resolve config for customer role`));
          }
          resolve(config.value);
        },
        error: (err) => {
          console.error(err);
          reject(err);
        }
      });
    });
  }

  // Triggers a new query for users with the matching input criteria.
  searchForUsers(type: 'phone' | 'email' | 'name') {
    const input = {
      filter: {},
      limit: 5,
    } as ListFullUsersWithoutCountQueryVariables;

    const val = this.customerForm.getRawValue();

    if (type === 'phone') {
      input.filter.phones = [val.phone];
    } else if (type === 'email') {
      input.filter.search = val.email;
    } else if (type === 'name') {
      input.filter.search = val.lastName?.length ? val.lastName : val.firstName;
    }

    if (this.usersQueryRef) {
      this.usersQueryRef.setVariables(input);
      return;
    }

    this.usersQueryRef = this.listUsersGQL.watch(input);
    this.subs.sink = this.usersQueryRef.valueChanges.subscribe((res) => {
      if (res.networkStatus === 7) {
        this.userSearchResults = res.data.usersv2.users;
      }
    });
  }

  async setUserFromParameters(userId: string) {
    const result = await lastValueFrom(this.listUsersGQL.fetch({
      filter: {
        userIds: [userId],
      }
    }));

    this.selectedUser = result.data?.usersv2?.users[0];

    await this.selectUser(this.selectedUser);
  }

  // Populate Form with the User Data
  async selectUser(user: FullUserFragment) {
    this.resolveUsedUsBefore(user.id);
    const val = this.customerForm.getRawValue();

    // Set to the user id so it is refelcted in the UI as the selected value
    this.customerType = user.id;
    this.customerForm.patchValue({
      userId: user.id,
      firstName: user.givenName,
      lastName: user.familyName,
      email: user.email,
      phone: user.phone,
      company: user.company,
      // usedUsBefore: val.usedUsBefore,
      // usedOtherBefore: val.usedOtherBefore,
      howDidTheyHearAboutUs: val.howDidTheyHearAboutUs || '',
    }, { emitEvent: true, onlySelf: false, });

    this.selectedUser = user;
  }

  /**
   * Fetches existing customer's invoices.
   * Sets the usedUsBefore control to true if it finds any.
   *
   * @param userId the ID of an existing customer
   */
  resolveUsedUsBefore(userId: string) {

    const filter = {
      userSearch: { userId, role: JOB_ROLE_MAP.customerRole },
      stage: 'invoice'
    };

    this.existingCustomerJobsService
      .fetch({ filter, limit: 3, sort: 'createdAt:desc' })
      .toPromise()
      .then((res) => {
        if (!res.data || !res.data.jobs) { return; }
        this.existingInvoices = res.data.jobs.jobs;
        // this.customerForm.patchValue({
        //   usedUsBefore: Boolean(this.existingInvoices.length)
        // });
      });

  }

  // If the sales agent selects the wrong user during the process they can use this to unselect them.
  unselectUser() {
    this.customerForm.controls.userId.setValue('');
    this.customerType = 'create';

    this.selectedUser = undefined;

    this.customerForm.controls.email.setValue(undefined);
    this.customerForm.controls.phone.setValue(undefined);

    const appliedCustomer = this.freyaHelper.getJobCustomerId(this.job);

    if (appliedCustomer) {
      this.customerForm.controls.userId.setValue(appliedCustomer);
    }
  }

  setExistingLead(exists: boolean) {
    this.customerForm.controls.existingLead.setValue(exists);
    this.customerForm.controls.existingLead.markAsTouched();
  }

  // getRuleData() {
  //   const value = this.customerForm.getRawValue();
  //   return {
  //     customer: {
  //       usedUsBefore: value.usedUsBefore,
  //       usedOtherBefore: value.usedOtherBefore,
  //     },
  //   };
  // }

  openMutateCustomer() {
    this.freyaMutate.openMutateObject({
      mutateType: 'update',
      objectType: 'user',
      object: {
        ...this.selectedUser,
        roles: this.selectedUser.roles || [],
        zones: this.selectedUser.zones || [],
      },
      removeSteps: ['Roles', 'Zones'],
    });
  }

  timelineStringToArray(timeline: string, timelineDays: number): [Date, Date] | [] {

    if (!timeline) {
      return [];
    }

    const mStart = dayjs.utc(timeline);

    if (!mStart.isValid()) {
      return [];
    }

    const mEnd = dayjs(mStart).add(timelineDays, 'days');

    const start = new Date(mStart.get('year'), mStart.get('month'), mStart.get('date'));

    const end = new Date(mEnd.get('year'), mEnd.get('month'), mEnd.get('date'));

    return [start, end];

  }

  onStepsClicked() {
    if (this.selectedUser) { return; }
    Object.keys(this.customerForm.controls)
      .map((controlName) => this.customerForm.controls[controlName])
      .forEach((ctrl) => ctrl.markAsDirty());
  }

  handleAddressChange(billingAddress: Address) {
    this.billingAddress = billingAddress;
    const control = this.customerForm.get('billingAddress');
    control.updateValueAndValidity();
  }

  clearBillingAddressControl() {
    const control = this.customerForm.get('billingAddress');
    control.setValue('');
    this.clearBillingAddress();
  }

  clearBillingAddress() {
    this.billingAddress = undefined;
    const control = this.customerForm.get('billingAddress');
    control.updateValueAndValidity();
  }

  isValidBillingAddress(control: AbstractControl): ValidationErrors | null {

    if (this.billingAddress && control?.value?.length) {
      return null;
    }

    if (!this.billingAddress && !control.value?.length) {
      return null;
    }

    return {
      invalidLocation: 'Please select a location from the autocomplete menu',
    };
  }

  /**
   * If the current value of a form control is not in the options list, this adds it to the list
   */
  updateOptions() {
    const formKeyOptionsMap: Partial<
      Record<keyof typeof this.customerForm.value,
      'jobOrigins' | 'customerTypes' | 'howHeardDropdownOptions'>
    > = {
      origin: 'jobOrigins',
      type: 'customerTypes',
      howDidTheyHearAboutUs: 'howHeardDropdownOptions',
    };

    for (const [ key, options ] of Object.entries(formKeyOptionsMap)) {

      const currentValue = this.customerForm.value[key];

      if (currentValue && !this[options].includes(currentValue)) {
        this[options] = [currentValue, ...this[options]];
      }
    }
  }

}
